import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as EditIcon } from "../../../Icons/EditIcon.svg"
import { ReactComponent as AddContactIcon } from "../../../Icons/AddContactIcon.svg"
import { useForm, Controller } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear';


export default function Details() {

    const [block, setBlock] = useState(1)
    const { formState: { errors }, handleSubmit, control } = useForm({
        mode: "onTouched"
    })

    const initialEmergencycontact = { name: "", contact_no: "" }
    const [EmergencyContact, setEmergencyContact] = useState([initialEmergencycontact])
    const [Details, setDetails] = useState({ name: "", nationality: "", contact_no: "", email: "" })

    const onSubmit = data => {
        const { name, nationality, contact_no, email } = data

        setDetails({ name: name, nationality: nationality, contact_no: contact_no, email: email })

        setEmergencyContact((prev) => {
            const dataas = EmergencyContact.map((itm, index) => ({
                Emergency_name: data[`Emergency_name${index + 1}`],
                Emergency_contact: data[`Emergency_contact${index + 1}`],
            }))
            return dataas
        })


    }


    console.log(EmergencyContact);
    console.log(Details);

    const handleDeleteContact = (ind) => {
        const data = EmergencyContact.filter((itm, index) => (ind !== index))
        setEmergencyContact(data)
    }

    const EditBlock = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={{ md: "start", xs: "center" }} columnGap={15}>
                    <Grid xs={12} md={4.5}>
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors.name) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Email</InputLabel>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors.email) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                        <Controller
                            name="contact_no"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <TextField error={Boolean(errors.contact_no) ? true : false} {...field} />}
                        />
                        <InputLabel sx={{ mt: 3, mb: 1 }} >Nationality</InputLabel>
                        <Controller
                            name="nationality"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => <Select {...field} sx={{ width: "100%" }} error={Boolean(errors.nationality) ? true : false} >
                                <MenuItem value={1}>India</MenuItem>
                            </Select>}
                        />

                    </Grid>
                    <Grid xs={12} md={4.5} mt={3} border=".5px solid #737373" sx={{ height: "fit-content", padding: "10px", borderRadius: "10px" }}>
                        {EmergencyContact?.map((item, index) => {
                            return (
                                <Box sx={{ mt: ((EmergencyContact.length) == (index + 1)) ? 5 : 0 }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h7_1">Emergency Contact {index + 1}</Typography>
                                        {index > 0 && <IconButton onClick={() => handleDeleteContact(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                                    <Controller
                                        name={`Emergency_name${index + 1}`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField error={Boolean(errors[`Emergency_name${index + 1}`]) ? true : false} {...field} />}
                                    />
                                    <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                                    <Controller
                                        name={`Emergency_contact${index + 1}`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <TextField error={Boolean(errors[`Emergency_contact${index + 1}`]) ? true : false} {...field} />}
                                    />
                                </Box>
                            )
                        })}
                        <Button onClick={() => setEmergencyContact((prev) => ([...prev, initialEmergencycontact]))} sx={{ mt: 3 }} startIcon={<AddContactIcon fill="#FFFFFF" />} variant="standard">Add Another Contact</Button>
                    </Grid>
                </Grid>
                <Stack mt={3} direction="row" alignItems="center" gap="30px">
                    <Button type="submit" variant="standard">Save</Button>
                    <Button onClick={() => { setBlock(1) }} variant="outlined">Cancel</Button>
                </Stack>
            </form>
        )
    }

    const ReviewBlock = () => (
        <Grid container columnGap={15}>
            <Grid>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Johnathan Doe</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Email</InputLabel>
                <Typography variant='h7_1' color="#1A2461">johnathandoe@mail.com</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                <Typography variant='h7_1' color="#1A2461">+65 654 987 321</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Nationality</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Indian</Typography>
            </Grid>
            <Grid mt={3} border=".5px solid #737373" sx={{ height: "fit-content", padding: "10px", borderRadius: "10px" }}>
                <Typography variant="h7_1">Emergency Contact 1</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Name</InputLabel>
                <Typography variant='h7_1' color="#1A2461">Johnathan Doe</Typography>
                <InputLabel sx={{ mt: 3, mb: 1 }} >Contact Number</InputLabel>
                <Typography variant='h7_1' color="#1A2461">+65 654 987 321</Typography>
            </Grid>
        </Grid>
    )




    return (
        <Box>
            <Stack mb={1} direction="row" alignItems="center" justifyContent="start" gap="100px">
                <Typography variant="h7_1">Personal details</Typography>
                <Button onClick={() => setBlock(2)} startIcon={<EditIcon fill="#FFFFFF" />} variant="contained">Edit</Button>
            </Stack>
            <Divider />
            {block == 1 && <ReviewBlock />}
            {block == 2 && <EditBlock />}
        </Box>
    )
}
