import React from 'react'
import CustomCarousel from '../../Components/Carousel'
import Header from '../../Components/Header'
import { Box, Stack, Button, Typography } from '@mui/material'
import { ServicesSteps } from "../../Data/index"
import Announcement from '../../Components/Main/Announcement'
import Hostels from '../../Components/Main/Hostels'
import News from '../../Components/Main/News'
import ServiceBgImg from "../../Icons/ServiceBgImg.svg"
import OurServices from '../../Components/Services/OurServices'
import { ReactComponent as Monitor } from "../../Icons/Services/Monitor.svg"
import { ReactComponent as TrustWorthy } from "../../Icons/Services/TrustWorthy.svg"
import { ReactComponent as OnlineSupport } from "../../Icons/Services/OnlineSupport.svg"
import ContactUs from '../../Components/Services/ContactUs'


export default function Services() {
    return (
        <>
            <Box sx={{ position: "relative" }}>
                <div
                    style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                >
                    <img
                        src={ServiceBgImg}
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            background: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0))",
                        }}
                    />
                </div>
                <Stack rowGap="20px" sx={{ position: "absolute", top: 0, transform: "translateY(30%)", left: "37%" }} direction="column" justifyContent="center" alignItems="center" textAlign="left" color="white" >
                    <Stack rowGap="10px" justifyContent="center" alignItems="center">
                        <Typography variant="h5_2">OUR SERVICES ARE ALWAYS</Typography>
                        <Typography variant="h2" sx={{ fontSize: "64px !important" }}>EXCELLENT</Typography>
                    </Stack>
                    <Button variant="contained" sx={{ width: "200px", height: "80px", padding: "10px 10px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1%", fontSize: "22px" }}>View Services</Button>
                </Stack>
                <Box display="flex" justifyContent="center">
                    <Stack direction="row" columnGap={6} sx={{ boxShadow: "0px 11px 30px 0px rgba(0,0,0,0.1)", height: "150px", position: "absolute", bottom: "-80px", backgroundColor: "white", padding: "40px", borderRadius: "24px" }} >
                        {ServicesSteps?.map((item, index) => (
                            <>
                                <Stack gap="10px" direction="column" justifyContent="center" alignItem="center" maxWidth="172px" textAlign="center" >
                                    <Box display="flex" justifyContent="center">
                                        <Box component="img" src={item?.icon}></Box>
                                    </Box>
                                    <Typography variant="h8_1" >{item?.name}</Typography>
                                </Stack>
                            </>
                        ))}
                    </Stack>
                </Box>
            </Box>
            <OurServices />
            <Stack my={7} alignItems="center" textAlign="center" gap={4}>
                <Typography p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">MAKE YOUR STAY WITH US</Typography>
                <Typography variant="h9_5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  <br />Ut enim ad minim veniam, quis nostrud exercitationullamco laboris nisi ut aliquip ex ea commodo consequat</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" gap={20}>
                <Stack direction="row" alignItems="center" gap="4px">
                    <Monitor />
                    <Typography variant="h9_3">Book your room online</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap="4px">
                    <OnlineSupport />
                    <Typography variant="h9_3">Online Support</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap="4px">
                    <TrustWorthy />
                    <Typography variant="h9_3">Trustworthy</Typography>
                </Stack>
            </Stack>
            <ContactUs />
        </>
    )
}
