import React, { useState } from 'react'
import { Box } from "@mui/material"
import ApplicationSteps from './ApplicationSteps'
import Contract from './Contract'
import ApplicationDetails from './ApplicationDetails'


export default function Application() {
    
    const [FlowCount, setFlowCount] = useState(1)

    return (
        <>
            <Box>
                {FlowCount == 1 && <ApplicationSteps setFlowCount={setFlowCount} />}
                {FlowCount == 2 && <ApplicationDetails setFlowCount={setFlowCount} />}
                {FlowCount == 3 && <Contract setFlowCount={setFlowCount} />}
            </Box>
        </>
    )
}
