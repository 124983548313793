import React from 'react'
import { Typography, Stack, Box, Grid, Divider, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

export default function PenaltyTerms({ setStepperCount }) {

    const { control, formState: { errors }, handleSubmit } = useForm({
        mode: "onTouched"
    })

    const onSubmit = (data) => {

        console.log(data);
        setStepperCount(2)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box px={{ md: 10, xs: 3 }} >
                <Typography variant="h6_1">Penalty Terms</Typography>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Divider />
                        <Grid mt={3} container justifyContent="start" columnGap={4}>
                            <Grid xs={12} sm={4}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography color="#1A2461" variant='h7_1'>PARTY B</Typography>
                                    <InputLabel sx={{ marginBottom: "0px" }} variant="standard">(“the Student”)</InputLabel>
                                </Stack>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Full name</InputLabel>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => <TextField error={Boolean(errors?.name) ? true : false} {...field} />}
                                />
                                <InputLabel sx={{ mt: 3 }} variant="standard">Passport Number</InputLabel>
                                <Controller
                                    name="passport_no"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => <TextField error={Boolean(errors?.passport_no) ? true : false} {...field} />}
                                />
                                <InputLabel sx={{ mt: 3 }} variant="standard">FIN Number</InputLabel>
                                <Controller
                                    name="fin_no"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => <TextField error={Boolean(errors?.fin_no) ? true : false} {...field} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}  >
                        <Stack textAlign="center" sx={{ border: "1px solid #1A2461", width: "100%", borderRadius: "8px", padding: "40px 60px" }}>
                            <Stack gap={1}>
                                <Typography variant="h6_1">Contract Break terms</Typography>
                                <Typography color="#1A2461" variant="h9_4">As of dated 11 May 2024, the contract break terms for the tenant is of follows</Typography>
                            </Stack>
                            <Stack mt={5} gap="10px" direction="column" alignItems="start" textAlign="start">
                                <Typography variant='h6_1'>Rental Payment & Deposit</Typography>
                                <Typography color="#1A2461" variant="h9_4">(1) Rental payment should be an one-off settlement. For a 2-year agreement, it can be settledon a yearly basis. Total rental payment at <br />
                                    (2) Upon signing of agreement, Party B must pay a rental deposit, equivalent to 1-month rental charge, at When this agreement expiring, the room condition will have to be the same as move-in condition, except fair wear and tear. In such case, full deposit will be refunded (without interest) to Party B. Whereby, if there is any property damaged in the room, Party B will have to compensate for the damage or loss, based on the price fixed in the signed checklist, and it will be deducted accordinglyfrom the rental deposit. Vinga Hall Management reserves the right to pursue any additional compensation whereby
                                    the rental deposit is not sufficient to cover the total cost of damage or loss.<br />
                                    (3) The rental deposit paid upon the signing of agreement will not be allowed to offset the monthly rental payment.<br />
                                    (4) If the rental payment is not settled by Party B on the due date, a penalty of 1%daily interest rate will be imposed on Party B, maximum cap at 10%. If the rental payment cannot be settled within 10 days from the due date, Party A reserves the right to terminate the Agreement, at any time, without further notice.<br />
                                    (5) If Party B did not fulfil the signed agreement’s full period of stay, Party A reserves the right not to return the paid rental amount to Party B, but Party A will refund the rental deposit toParty B, provided that no damage is found in the room.<br />
                                    (6) Upon agreement expiration, if Party B decided not to renew the lease, Party B must fill upthe Check-Out notice form (see attached) one month before the agreement expiry date, and submit to Vinga Hall Management. Failure to do so will result in the rental deposit not being refunded.<br />
                                    (7) One month before the agreement is expiring, Party B must give full 1-month advancednotice, either to continue staying in Vinga Hall or to move out. Without notice given by Party B, theagreement will be automatically renew with the same duration as the previous agreement.<br />
                                    (8) Party A reserves the right not to renew the agreement with Party B upon agreement expiration, on a case by case basis.</Typography>
                            </Stack>
                        </Stack>
                        <Stack mt={2} gap="5px" direction="row" alignItems="center" justifyContent="start" >
                            <Controller
                                name="check"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Checkbox error={Boolean(errors?.check) ? true : false} {...field} />}
                            />
                            <Typography variant='h9_3' >I have read the penalty terms and I agree with the terms and conditions</Typography>
                        </Stack>
                        {Boolean(errors?.check) && <InputLabel sx={{ mt: 1, color: "red" }} variant="standard">Please select the Checkbox If you want to proceed</InputLabel>}

                    </Grid>
                </Grid>
                <Typography variant='h9_1' color="#1A2461">Signed by</Typography><br />
                <Controller
                    name="sign"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <TextField sx={{ width: "260px", '.MuiInputBase-input': { backgroundColor: "#e6f2fa" }, mt: 2 }} variant="filled" error={Boolean(errors?.sign) ? true : false} {...field} />}
                />

                <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                    <Button type="submit" variant="standard">Submit</Button>
                </Stack>
            </Box >
        </form>
    )
}
