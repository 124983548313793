import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField, IconButton } from '@mui/material'
import PaynowIcon from "../../../../Icons/PaynowIcon.svg"
import VisaIcon from "../../../../Icons/Visa.svg"

export default function Payment({ setStepperCount }) {

  return (
    <>
      <Box px={{ md: 10, xs: 3 }}>
        <Typography variant="h6_1">Payment</Typography>
        <Grid mt={3} mb={6} container rowGap={7}>
          <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
            <Divider />
            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px">
                <Typography variant="h6_1">Room Rent</Typography>
                <Typography variant='h9_4' >VINGA HALL @ BOON KENG (BOARDING HOUSE)</Typography>
                <Typography variant='h9_4' >Single (Master Room) for 12-months contact</Typography>
              </Stack>
              <Typography variant="h6_1">S$ 3800 * 12</Typography>
            </Stack>
            <Divider />
            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px">
                <Typography variant="h6_1">Maintenance fee</Typography>
                <Typography variant='h9_4' >The fixed maintenance fee for the room </Typography>
              </Stack>
              <Typography variant="h6_1">S$ 500</Typography>
            </Stack>
            <Divider />
            <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px">
                <Typography variant="h6_1">Fixed fee</Typography>
                <Typography variant='h9_4' >A fixed additional fee for the room</Typography>
              </Stack>
              <Typography variant="h6_1">S$ 50</Typography>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack gap="8px">
                <Typography variant="h6_1">Total</Typography>
              </Stack>
              <Typography variant="h6_1">S$ 46,150</Typography>
            </Stack>
            <Stack mt={10} direction="column" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography variant="h6_1">Pay Via</Typography>
              <Stack gap="8px" direction="row" justifyContent="space-between" alignItems="center">
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                </Stack>
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={PaynowIcon}></Box>
                </Stack>
                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                  <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}
