import React, { useState, useEffect } from 'react'
import AnnouncementImg from "../../../Icons/AnnouncementBg.svg"
import { ReactComponent as BellIcon } from "../../../Icons/BellIcon.svg"
import { ReactComponent as ApplicationIcon } from "../../../Icons/application.svg"
import { ReactComponent as RightUpArrowIcon } from "../../../Icons/rightUpArrow.svg"
import { ReactComponent as Course_trainingIcon } from "../../../Icons/Announcement/course_training.svg"
import { ReactComponent as Dental_appointmentIcon } from "../../../Icons/Announcement/dental_appointment.svg"
import { ReactComponent as English_trialclaIcon } from "../../../Icons/Announcement/english_trial_cls.svg"
import { ReactComponent as Extracurricular_activitiesIcon } from "../../../Icons/Announcement/extracurricular_activities.svg"
import { ReactComponent as Rent_paymentIcon } from "../../../Icons/Announcement/rent_payment.svg"
import { ReactComponent as Repair_applicationIcon } from "../../../Icons/Announcement/repair_application.svg"
import { ReactComponent as Weekend_activitiesIcon } from "../../../Icons/Announcement/weekend_activities.svg"
import { ReactComponent as University_tutionIcon } from "../../../Icons/Announcement/university_tution.svg"
import { ReactComponent as Subject_tutionIcon } from "../../../Icons/Announcement/subject_tution.svg"
import { Typography, Box, Divider, Button, Stack, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as LeftArrowIcon } from "../../../Icons/leftArrow.svg"
import { ReactComponent as RightArrowIcon } from "../../../Icons/rightArrow.svg"
import { ReactComponent as ShareIcon } from "../../../Icons/ShareIcon.svg"
import { ReactComponent as FavouritesIcon } from "../../../Icons/favouritesIcon.svg"
import Bgimg from "../../../Icons/bgimg.svg"

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import parse from "html-react-parser";

export default function NewsList() {

    const [announcementData, setAnnouncementData] = useState([])


    const { id } = useParams()

    const [NewsData, setNewsData] = useState([])

    const getAllNews = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllNews`);
            console.log(response?.data?.data);
            setNewsData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }


    const getAllAnnouncement = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllAnnouncement`)
            console.log(response?.data?.data);
            setAnnouncementData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getAllNews()
        getAllAnnouncement()
    }, [])


    const images = [
        {
            src: Bgimg,
        },
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
    ];

    const [NewsTags, setNewsTags] = useState(["tag", "tag", "tag_name", "tag", "tag_name", "tag", "tag_name", "tag_name", "tag", "tag_name", "tag_name", "tag"])


    return (
        <Box>

            <Grid justifyContent="space-between" container sx={{ backgroundColor: "#EFF4FC" }}>
                <Grid xs={12} md={5.7} lg={6}>
                    <Box component="img" src={AnnouncementImg} sx={{ width: "100%", height: "713px", objectFit: "none" }} ></Box>
                </Grid>
                <Grid display="flex" flexDirection="column" justifyContent="start" alignItems="center" backgroundColor="#EFF4FC" xs={12} md={5.7} lg={6} px={2}>
                    <Stack py={6} direction="row" justifyContent="start">
                        <Typography p={2} variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">ANNOUNCEMENTS</Typography>
                    </Stack>
                    <Stack sx={{ backgroundColor: "white", border: "0.5px solid #27BAC4", borderRadius: "20px", width: "auto", }}>
                        <Typography p={2} variant="h6_2" color="primary" textAlign="start" borderBottom="0.5px solid #27BAC4">Announcements</Typography>
                        <Stack rowGap='20px' p={{ sm: 3, xs: 1 }} sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { display: "none" } }}>
                            {announcementData?.map((item, index) => (
                                <>
                                    <Stack direction="row" columnGap={{ sm: 7, xs: 3 }} justifyContent="space-between" minHeight="53px">
                                        <Stack direction="row" columnGap="10px" alignItems="center">
                                            <Box sx={{ width: "45px", height: "45px", borderRadius: "100px", backgroundColor: "button", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <BellIcon />
                                            </Box>
                                            <Box display="flex" flexDirection="column" textAlign="start">
                                                <Typography variant="h6_1" color="text_blue">{item?.title}</Typography>
                                                <Typography variant="h9_2" color="text_blue">{item?.subtitle}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack alignItems="end" justifyContent="center">
                                            <Typography variant="h9_2" color="#606060">{item?.time}</Typography>
                                            <Typography variant="h9_2" color="#606060">{item?.date}</Typography>
                                        </Stack>
                                    </Stack>
                                </>
                            ))}
                        </Stack>
                    </Stack>
                </Grid>
            </Grid >

            <Box mt={7} width="100%">
                <Stack px={5} direction="row" justifyContent="center">
                    <Typography p={2} variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">NEWS</Typography>
                </Stack>
            </Box>
            <Grid container gap="30px" mt={5} pb={10} px={2} justifyContent="center" marginBottom='0px'>
                <Grid md={7} container gap="30px">
                    {NewsData?.map((itm, index) => (
                        <>
                            <Grid>
                                <Card sx={{ p: 1, pt: { sm: 1, xs: 2 }, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", display: "flex", flexDirection: { sm: "row", xs: "column-reverse" }, jsutifyContent: "center", alignItems: "center" }}>
                                    <CardActionArea>
                                        <CardContent >
                                            <Stack direction="column" alignItems="start" gap="10px">
                                                <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                                <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                                    {itm?.title}
                                                </Typography>
                                                <Typography variant="h9_4">
                                                    {Boolean(itm?.description) ? parse(itm?.description) : "-"}
                                                </Typography>
                                            </Stack>
                                            <Stack mt={4} direction="row" gap="20px">
                                                <Box sx={{ width: "40px", height: "40px", borderRadius: "100px", backgroundColor: "#27BAC4", display: "flex", justifyContent: "center", alignItems: "center" }}> <FavouritesIcon fill="#FFFFFF" /></Box>
                                                <Box sx={{ width: "40px", height: "40px", borderRadius: "100px", backgroundColor: "#27BAC4", display: "flex", justifyContent: "center", alignItems: "center" }}><ShareIcon fill="#FFFFFF" /></Box>
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardMedia sx={{ borderRadius: "20px", height: "240px", width: "240px", px: 1 }}
                                        component="img"
                                        image={process.env.REACT_APP_FILE_PATH + itm?.img}
                                        alt="green iguana"
                                    />
                                </Card>
                            </Grid>
                        </>
                    ))}
                </Grid>
                <Grid md={3} p={1} px={2} borderLeft=".5px solid #737373">
                    <Typography variant="h6_1">Tags</Typography>
                    <Box mt={2} gap="20px" sx={{ display: "flex", flexWrap: "wrap" }}>
                        {NewsTags?.map((item, index) => (<Typography variant="h9_4" color="#007ECA" sx={{ width: "fit-content", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>{item}</Typography>))}
                    </Box>
                </Grid>
            </Grid>

        </Box >
    )
}
