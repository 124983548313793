import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Grid, TableContainer, Table, TextField, InputLabel, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import moment from "moment"
import axios from "axios"

export default function AnnouncementAdd() {

    const navigate = useNavigate()


    const { control, formState: { errors }, handleSubmit } = useForm({
        mode: "onTouched"
    })


    const onSubmit = async (data) => {
        console.log(data)

        const datas = {
            title: data?.title,
            subtitle: data?.subtitle,
            date: moment(data?.date).format("YYYY.DD.MM"),
            time: moment(data?.time).format("hh:mm A")
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}addAnnouncement`, datas)
            console.log(response);
            navigate('/Admin')
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Box mb={30}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h7_1">Announcement</Typography>
                </Stack>
                <Divider sx={{ mb: 3, mt: 2 }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container xs={8} columnGap={4} pb={10} rowGap={3} justifyContent="space-between">
                        <Grid xs={12} md={5.5}>
                            <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                            <Controller
                                name="title"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TextField error={Boolean(errors?.title) ? true : false} {...field} />}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5}>
                            <InputLabel sx={{ mb: 1 }}>Subtitle</InputLabel>
                            <Controller
                                name="subtitle"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TextField error={Boolean(errors?.subtitle) ? true : false} {...field} />}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5}>
                            <InputLabel sx={{ mb: 1 }}>Date</InputLabel>
                            <Controller
                                name="date"
                                rules={{ required: true }}
                                control={control}
                                // render={({ field }) => <TextField error={Boolean(errors?.title) ? true : false} {...field} />}
                                render={({ field }) => <DateField {...field} error={Boolean(errors?.date) ? true : false} sx={{ width: "100%", '.MuiSvgIcon-root': { fill: "#1A2461" } }} fullWidth />}
                            />
                        </Grid>
                        <Grid xs={12} md={5.5}>
                            <InputLabel sx={{ mb: 1 }}>Time</InputLabel>
                            <Controller
                                name="time"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TimeField error={Boolean(errors?.time) ? true : false} sx={{ width: "100%" }} {...field} />}
                            />
                        </Grid>
                        <Grid mt={3} xs={12} container justifyContent="end">
                            <Button variant="contained" type="submit">Confirm</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
