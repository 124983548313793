import React, { useEffect, useState } from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Accordion } from '@mui/material'
import { ReactComponent as DownArrowIcon } from "../../../../Icons/DownArrow.svg"
import { useDispatch, useSelector } from "react-redux"

import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Room({ setStepperCount, setRoomsData }) {

    const { HostelData } = useSelector((state) => (state.CommonData))
    const [hostelData, setHostelData] = useState(HostelData)

    const [selectedHostelRooms, setSelectedHostelRoom] = useState(null)
    const [contractLength, setContractLength] = useState(null)


    useEffect(() => {
        setRoomsData({
            selectedHostelRooms: selectedHostelRooms,
            contractLength: contractLength
        })
    }, [selectedHostelRooms, selectedHostelRooms])
    console.log(hostelData);
    console.log(selectedHostelRooms);


    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Select Preferred room</Typography>
            <Box my={5}>
                {hostelData?.map((mainitem, index) => (
                    <Accordion >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant='h7_1' color="button">{mainitem?.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid mt={3} mb={6} container rowGap={7}>
                                {mainitem?.HostelTypes?.map((item, index) => (
                                    <Grid xs={12}>
                                        <Typography variant='h7_1' color="button">{item?.Hostel_type}</Typography>
                                        <Divider sx={{ my: 2 }} />
                                        {item?.room_type?.map((itm, ind) => (
                                            <Stack onClick={() => setSelectedHostelRoom({ hostelId: item?.id, HostelName: item?.Hostel_type, ...itm })} mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                                                <Stack direction="row" alignItems="center">
                                                    <Radio checked={(item?.id == selectedHostelRooms?.hostelId) && (itm?.id == selectedHostelRooms?.id)} />
                                                    <Typography variant='h7_1'>{itm?.room_type_name}</Typography>
                                                </Stack>
                                                {/* <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typography sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack> */}
                                            </Stack>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            {/* <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ RG (Room For Rent)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Room with Bathroom(With window)</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typography sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ Boon Keng (Boarding House)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Sharing(Master room)</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typography sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Single(Master room)</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typography sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ Boon Keng (Room For Rent)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Sharing(Master room)</Typography>
                        </Stack>
                           <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typogr<Stack direction="row" alignItems="center" gap="16px">
                         aphy sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Single(Master room)</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Available rooms</Typography>
                            <Typography sx={{ px: 2, py: 1.5, backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", }} variant='h9_1' color="#1A2461">15</Typography>
                        </Stack>
                    </Stack>
                </Grid>*/}


            <Typography variant='h7_1'>Room details</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="space-between">
                <Grid md={5.5}>
                    <Typography variant='h7_1'>Contract Length</Typography><br />
                    <InputLabel sx={{ mt: 3 }} variant="standard"> Contract Length</InputLabel>
                    <Select fullWidth onChange={(e) => setContractLength(e.target.value)}>
                        <MenuItem value={1}>6 Months</MenuItem>
                        <MenuItem value={2}>12 Months</MenuItem>
                    </Select>
                </Grid>
                {(Boolean(contractLength) && Boolean(selectedHostelRooms)) &&
                    <Grid md={5.5} gap={1} textAlign="center" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Typography variant='h7_1'>Selected room preview</Typography>
                        <Box mt={1} component="img" sx={{ width: { sm: 450, xs: "100%" }, height: { sm: 450, xs: 'auto' }, objectFit: "cover", border: ".5px solid #1A2461", borderRadius: "8px" }} src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"></Box>
                        <Typography variant='h7_1' color="button">{selectedHostelRooms?.HostelName}</Typography>
                        <Typography variant='h7_1' color="#003893">{selectedHostelRooms?.room_type_name}</Typography>
                        {/* <Typography variant='h7_1' color="#003893">With window</Typography> */}
                        {contractLength === 1 &&
                            <Typography variant='h7_1' color="#27BAC4">S${selectedHostelRooms?.six_month_cont}/month</Typography>
                        }
                        {contractLength === 2 &&
                            <Typography variant='h7_1' color="#27BAC4">S${selectedHostelRooms?.twelve_month_cont}/month</Typography>
                        }
                    </Grid>
                }
            </Grid>
            <Stack my={5} mx={5} alignItems="end">
                <Button onClick={() => { setStepperCount(2) }} variant="standard">Continue</Button>
            </Stack>
        </Box >
    )
}
