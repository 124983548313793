import React, { useState, useRef } from 'react'
import { Typography, Box, Divider, Button, Grid, TableContainer, Table, TextField, InputLabel, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { ReactComponent as UploadFileIcon } from "../../../Icons/UploadFileIcon.svg"
import { ReactComponent as ImgIcon } from "../../../Icons/ImgIcon.svg"
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios"


export default function NewsAdd() {

    const navigate = useNavigate()

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"], // superscript/subscript
        ],
    };


    const { control, formState: { errors }, setValue, handleSubmit, register, watch } = useForm({
        mode: "onTouched"
    })
    const Imgwatch = watch("img")
    const imgFile = useRef()
    const [Newsdata, setNewsdata] = useState({ title: "", description: "", img: "" })

    const onSubmit = async (data) => {
        console.log(data)
        setNewsdata({ title: data?.title, description: data?.description, img: data?.img })

        const formData = new FormData()

        formData.append('title', data?.title)
        formData.append('description', data?.description)
        formData.append('img', data?.img)


        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}addnews`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }); 
            navigate('/Admin/NewsList')
            console.log(response);
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <>
            <Box mb={30}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h7_1">News</Typography>
                </Stack>
                <Divider sx={{ mb: 3, mt: 2 }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container xs={8} columnGap={4} rowGap={3} justifyContent="space-between">
                        <Grid xs={12}>
                            <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                            <Controller
                                name="title"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TextField error={Boolean(errors?.title) ? true : false} {...field} />}
                            />
                        </Grid>
                        <Grid xs={12} mb={6}>
                            <InputLabel sx={{ mb: 1 }}>Description</InputLabel>
                            <Controller
                                name="description"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <ReactQuill
                                    style={{ height: "180px" }}
                                    modules={modules}
                                    maxLength={3000}
                                    {...field}
                                />}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <InputLabel sx={{ mb: 1 }}>Image</InputLabel>

                            {Boolean(Imgwatch) ?
                                <Stack direction="row" justifyContent="space-between" alignItems="center" padding="23px" borderRadius="8px" sx={{ border: ".5px solid #1A2461" }}>
                                    <Typography variant="h9_1" sx={{ display: "flex", alignItems: "center", gap: "10px" }}><ImgIcon />{Imgwatch?.name}</Typography>
                                    <ClearIcon onClick={() => setValue("img", null)} sx={{ color: "#1A2461" }} />
                                </Stack> :
                                <Box xs={12} onClick={() => imgFile.current.click()} sx={{ border: Boolean(errors.img) ? "1px dashed red" : "1px dashed #1A2461", height: "200px", borderRadius: "8px", backgroundColor: "#F9FBFF" }}>
                                    <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                                        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: "100px", width: "100px", borderRadius: "100px", backgroundColor: "#F2F2F2", }}>
                                            <UploadFileIcon fill="#1A2461" />
                                        </Stack>
                                        <Stack mt={2} direction="row" alignItems="center" gap="5px">
                                            <Typography variant='h9_1'>Click to upload</Typography>
                                        </Stack>
                                        <Typography variant='h9_5'>Allowed formats - JPEG, PNG</Typography>
                                    </Stack>
                                </Box>
                            }
                            <Controller
                                name="img"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => { field.onChange(e.target.files[0]); e.target.value = null }}
                                        ref={(e) => {
                                            imgFile.current = e;
                                        }}
                                        hidden
                                    />
                                )}
                            />
                        </Grid>

                        <Grid mt={3} xs={12} container justifyContent="end">
                            <Button variant="contained" type="submit">Confirm</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
