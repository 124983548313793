import React, { useState, useRef } from 'react'
import { Typography, Box, Divider, Button, Grid, TableContainer, Table, TextField, IconButton, InputLabel, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { ReactComponent as UploadFileIcon } from "../../Icons/UploadFileIcon.svg"
import { ReactComponent as ImgIcon } from "../../Icons/ImgIcon.svg"
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios"


export default function Services() {

    const navigate = useNavigate()
    const modules = {
        toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"], // superscript/subscript
        ],
    };

    const { control, formState: { errors }, setValue, handleSubmit, watch, reset } = useForm({
        mode: "onTouched",
        defaultValues: {
            other_chargeble_Service: [{ id: 1, title: "", amount: "" }],
            landing_services_package: { name: "", amount: "" }
        }
    })

    const [Newsdata, setNewsdata] = useState({ title: "", description: "", img: "" })


    const initialOtherServices = { id: 1, title: "", amount: "" }
    const [OtherServices, setOtherServices] = useState([initialOtherServices])


    const handleDeleteOtherServices = (ind) => {
        const data = OtherServices.filter((itm, index) => (ind != index))
        setOtherServices(data)
    }


    const { fields: OtherServicesFields, append: AppendOtherServices, remove: removeOtherServices } = useFieldArray({
        control,
        name: 'other_chargeble_Service'
    });
    // const { fields: landing_services_package } = useFieldArray({
    //     control,
    //     name: 'landing_services_package'
    // });



    const onSubmit = async (data) => {
        console.log(data)

        const datas = {
            landing_services_package: data?.landing_services_package,
            other_chargeble_Service: data?.other_chargeble_Service
        }



        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}addServices`, datas)
            console.log(response);
            // reset()
        } catch (error) {
            console.log(error);
        }

    }


    return (
        <>
            <Box mb={30}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h7_1">Landing Service Package</Typography>
                </Stack>
                <Divider sx={{ mb: 3, mt: 2 }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container xs={8} columnGap={4} rowGap={3} justifyContent="space-between">
                        <Grid xs={12} mb={6}>
                            <Controller
                                name="landing_services_package.name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <ReactQuill
                                    style={{ height: "180px" }}
                                    modules={modules}
                                    maxLength={3000}
                                    {...field}
                                />}
                            />
                        </Grid>

                        <Grid xs={12}>
                            <InputLabel sx={{ mb: 1 }}>Amount</InputLabel>
                            <Controller
                                name="landing_services_package.amount"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => <TextField type="number" error={Boolean(errors?.amount) ? true : false} {...field} />}
                            />
                        </Grid>
                        <Grid container xs={12} columnGap={4} justifyContent="space-between">
                            <Grid mb={3} xs={12}>
                                <Typography variant="h7_1">Other Chargeable Services</Typography>
                            </Grid>
                            {OtherServicesFields?.map((item, index) => (
                                <>
                                    <Grid container xs={12} justifyContent="end">
                                        {index > 0 && <IconButton onClick={() => removeOtherServices(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Grid>
                                    <Grid xs={12} md={5.5}>
                                        <InputLabel sx={{ mb: 1 }}>title</InputLabel>
                                        <Controller
                                            name={`other_chargeble_Service.${index}.title`}
                                            defaultValue={item.title}
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => <TextField error={Boolean(errors[`title_${index}`]) ? true : false} {...field} />}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={5.5}>
                                        <InputLabel sx={{ mb: 1 }}>Amount</InputLabel>
                                        <Controller
                                            name={`other_chargeble_Service.${index}.amount`}
                                            defaultValue={item.amount}
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => <TextField type="number" error={Boolean(errors[`amount_${index}`]) ? true : false} {...field} />}
                                        />
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                        <Grid my={5} xs={12}>
                            <Button onClick={() => AppendOtherServices({ id: OtherServicesFields.length + 1, title: "", amount: "" })} variant="contained">Add more hostel type</Button>
                        </Grid>

                        <Grid mt={3} xs={12} container justifyContent="end">
                            <Button variant="contained" type="submit">Confirm</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
