import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../AxiosInstance/index"

const initialState = {
    HostelData: [],
    AnnouncementData: [],
    ServicesData: {}
}



export const HostelGetAll = createAsyncThunk('HostelGetAll', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.get('getAllhostel')
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
},
)


export const AnnouncementGetAll = createAsyncThunk('AnnouncementGetAll', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.get('getAllAnnouncement')
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
},
)


export const ServicesGetAll = createAsyncThunk('ServicesGetAll', async (userData, { rejectWithValue }) => {
    try {
        const response = await axios.get('getAllServices')
        return response
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
},
)


const CommonData = createSlice({
    name: "CommonData",
    initialState,
    reducers: {
        setHostelData: (state, action) => {
            state.HostelData = action.payload
        }
    },
    extraReducers: (builders) => {
        builders.addCase(HostelGetAll.fulfilled, (state, action) => {
            state.HostelData = action.payload?.data?.data
        })
        builders.addCase(HostelGetAll.rejected, (state, action) => {
            console.log(action);
            // state.HostelData = action.payload?.data?.data
        })
        builders.addCase(AnnouncementGetAll.fulfilled, (state, action) => {
            state.AnnouncementData = action.payload?.data?.data
        })
        builders.addCase(AnnouncementGetAll.rejected, (state, action) => {
            console.log(action);
            // state.HostelData = action.payload?.data?.data
        })
        builders.addCase(ServicesGetAll.fulfilled, (state, action) => {
            console.log(action.payload?.data?.data);
            state.ServicesData = action.payload?.data?.data
        })
        builders.addCase(ServicesGetAll.rejected, (state, action) => {
            console.log(action);
            // state.HostelData = action.payload?.data?.data
        })
    }
})


export default CommonData.reducer;