import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as ContractIcon } from "../../../Icons/Account/Contract.svg"
import { ReactComponent as SandClockIcon } from "../../../Icons/Account/SandClock.svg"
import { ReactComponent as PuzzlePiecesIcon } from "../../../Icons/Account/PuzzlePieces.svg"
import { ReactComponent as TickIcon } from "../../../Icons/Account/TickIcon.svg"
import { ReactComponent as ApprovedIcon } from "../../../Icons/Account/ApprovedIcon.svg"
import { ReactComponent as RightArrowIcon } from "../../../Icons/rightArrow.svg"
import { ReactComponent as DownloadIcon } from "../../../Icons/Account/Download.svg"
import { ReactComponent as ShareIcon } from "../../../Icons/ShareIcon.svg"
import { ReactComponent as RejectedIcon } from "../../../Icons/Account/RejectedIcon.svg"



export default function Application() {
    return (
        <Box>
            <Stack mb={1} direction="row" alignItems="center" justifyContent="start" gap="100px">
                <Typography variant="h7_1">Application</Typography>
            </Stack>
            <Divider />
            <Stack mt={2} direction="column" gap="20px" sx={{ border: ".5px solid #737373", borderRadius: "8px", padding: "20px 30px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" gap="20px">
                        <ContractIcon />
                        <Box>
                            <Typography variant="h7_2">Vinga Hall <br /> Application Status</Typography>
                            <Stack mt={1} direction="row" justifyContent="center" alignItems="center" gap="8px" sx={{ width: "120px", height: "30px", border: "1px solid #52AD33", backgroundColor: "#eef7eb", borderRadius: "30px", padding: "10px", color: "#52AD33" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Approved</Typography>
                                <ApprovedIcon />
                            </Stack>
                        </Box>
                    </Stack>

                    {/*Status Pending */}

                    {/* <Stack direction="row" alignItems="center" gap="8px" sx={{ border: "1px solid #C38236", backgroundColor: "#f9f3eb", borderRadius: "30px", padding: "10px 20px", color: "#C38236" }}>
                        <Typography sx={{ fontWeight: 700 }} variant="h9_5">Pending  <br /> Approval</Typography>
                        <SandClockIcon />
                    </Stack> */}

                    {/*Status Rejected */}

                    {/* <Stack direction="row" alignItems="center" gap="8px" sx={{ border: "1px solid #C81919", backgroundColor: "#fae8e8", borderRadius: "30px", padding: "10px 20px", color: "#C81919" }}>
                        <Typography sx={{ fontWeight: 700 }} variant="h9_5">Rejected</Typography>
                        <RejectedIcon />
                    </Stack> */}

                    {/*Status Approved */}

                    {/* <Button endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700 }}>Read & Sign</Button> */}

                    {/*Status Signed */}

                    {/* <Button endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, backgroundColor: "#99caeb" }}>Signed</Button> */}

                </Stack>
                <Typography color="#003893" variant="h7_1">Applied for</Typography>
                <Stack direction="row" justifyContent="start" gap="20px">
                    <Stack direction="row" justifyContent="space-between" gap="7px" sx={{ width: "fit-content", padding: "20px", borderRadius: "20px", border: ".5px solid #737373" }}>
                        <Box component="img" sx={{ width: { sm: 128, xs: "100%" }, height: { sm: 128, xs: 'auto' }, objectFit: "cover", border: ".5px solid #1A2461", borderRadius: "8px" }} src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"></Box>
                        <Stack direction="column" gap="7px">
                            <Typography variant="h7_1" color="#007ECA">Vinga Hall @ RG (Boarding House)</Typography>
                            <Typography variant="h9_4" color="#003893">Twin room with bathroom</Typography>
                            <Typography variant="h9_4" color="#003893">With window</Typography>
                        </Stack>
                    </Stack >
                    <Stack direction="column" gap="7px" sx={{ width: "fit-content", height: "fit-content", padding: "20px", borderRadius: "20px", border: ".5px solid #737373" }}>
                        <Stack direction="row" alignItems="center" gap="10px">
                            <PuzzlePiecesIcon />
                            <Typography variant="h9_1" color="#003893">Other Chargeable Services</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">With window</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="10px">
                            <TickIcon fill="#1A2461" />
                            <Typography variant="h9_4" color="#1A2461">Counselling</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Stack mt={4} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                <Stack direction="column" gap="10px">
                    <Typography variant='h9_1'>Download Contract</Typography>
                    <Typography variant='h9_2'>Download the approved contract as PDF</Typography>
                </Stack>
                <Button endIcon={<DownloadIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, }}>Contract</Button>
            </Stack>

            <Stack mt={4} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                <Stack direction="column" gap="10px">
                    <Typography variant='h9_1'>Download Invoice</Typography>
                    <Typography variant='h9_2'>Download the invoice as PDF</Typography>
                </Stack>
                <Stack direction="row" gap="10px">
                    <Button endIcon={<DownloadIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, }}>Invoice</Button>
                    <Button startIcon={<ShareIcon fill="#FFFFFF" />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700, width: "fit-content", padding: "6px 7px 6px 15px", minWidth: "10px" }}></Button>
                </Stack>
            </Stack>
        </Box >
    )
}
