import { configureStore } from "@reduxjs/toolkit"
import CommonReducer from "../Feature/CommonSlice"

const store = configureStore({
    reducer: {
        CommonData: CommonReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
})


export default store