import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Menu, MenuItem, ListItemIcon } from '@mui/material'
import UserCircleIcon from "../Icons/UserCircleIcon.svg"
import { ReactComponent as UserIcon } from "../Icons/UserIcon.svg"
import { ReactComponent as LogoutIcon } from "../Icons/LogoutIcon.svg"
import UKIcon from "../Icons/UKIcon.svg"
import { ReactComponent as DownArrowIcon } from "../Icons/DownArrow.svg"


export default function HeaderTop() {

    const navigate = useNavigate()
    const [anchorlEl, setanchorlEl] = useState(null)
    const open = Boolean(anchorlEl)

    return (
        <>
            <Box height="70px" sx={{ backgroundColor: "#003893", display: "flex", alignItems: "center", justifyContent: "end", gap: "20px", px: 3 }}>

                {/* before LOgin */}

                {/* <Box sx={{ width: "100px", height: "40px", borderRadius: "8px", paddding: "10px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
                <Box component="img" src={UserCircleIcon}></Box>
                <Typography variant="h9_3" color="#017BCC">Login</Typography>
            </Box> */}

                {/* after Login */}


                <Box onClick={(e) => { setanchorlEl(e.currentTarget) }} px={1.3} sx={{ width: "fit-content", height: "40px", borderRadius: "8px", paddding: "10px !important", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
                    <Box component="img" src={UserCircleIcon}></Box>
                    <Typography variant="h9_3" color="#017BCC" >Username</Typography>
                    <DownArrowIcon fill="#017BCC" />
                </Box>

                <Box sx={{ width: "100px", height: "40px", borderRadius: "8px", paddding: "10px", backgroundColor: "#FFFFFF", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center" }}>
                    <Box component="img" src={UKIcon}></Box>
                    <Typography variant="h9_3" color="#017BCC" sx={{ fontWeight: 700 }}>EN</Typography>
                    <DownArrowIcon fill="#017BCC" />
                </Box>
            </Box>

            <Menu open={open} anchorEl={anchorlEl} onClose={() => setanchorlEl(null)} sx={{ '& .MuiAvatar-root': { p: 2 }, mt: 1 }}>
                <MenuItem gap="10px" onClick={() => { navigate('account'); setanchorlEl(null) }}>
                    <ListItemIcon>
                        <UserIcon />
                    </ListItemIcon>
                    <Typography color="#017BCC" variant="h7_3">Account</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <Typography color="#017BCC" variant="h7_3">Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}
