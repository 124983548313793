import React, { useEffect, useRef, useState } from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField, IconButton } from '@mui/material'
import { ReactComponent as EditIcon } from "../../../../Icons/EditIcon.svg"
import moment from "moment"
import { useSelector } from 'react-redux';


export default function Review({ setStepperCount, ServicesValue, PersonalData, RoomsData, HandleSubmitForm }) {
    // const initial_Emergency_contact = { Emergency_name: "", Emergency_contact_no: "", Emergency_email: "", Emergency_whatsapp: "", Emergency_wechat: "", Emergency_line: "", Emergency_kakaotalk: "" }
    // const [Emergency_contact, setEmergency_contact] = useState([initial_Emergency_contact])
    // const [Details, setDetails] = useState({ name: "", dob: "", nationality: "", gender: "", passport_no: "", school: "", contact_no: "", wechat: "", whatsapp: "", line: "", kakaotalk: "", note: "", ipaFile: "" })
    const { ServicesData } = useSelector((state) => (state.CommonData))
    const { Details, Emergency_contact } = PersonalData

    const { otherServices, fligt_no, arrival_date, arrival_time, landingServices } = ServicesValue
    console.log(ServicesData);

    console.log(ServicesValue);

    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Applicant Information</Typography>
            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Stack direction="row" alignItems="center" columnGap={10} justifyContent="start">
                        <Typography variant='h7_1'>Services</Typography>
                        <Button onClick={() => { setStepperCount(3) }} startIcon={<EditIcon fill="#FFFFFF" />} sx={{ height: "40px", width: "100px", fontSize: "16px", fontWeight: 500 }} variant="standard">Edit</Button>
                    </Stack>
                    <Divider />
                    <Typography variant='h7_3'>Flight Details</Typography>

                    <Grid container justifyContent="start">
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Flight Number</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{ServicesValue?.fligt_no}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5} rowGap="15px">
                            <Typography variant='h7_2'>Landing Services (Optional)</Typography><br />
                            {Boolean(landingServices) ?
                                <Typography lineHeight="37px" variant='h9_1' color="green">Included</Typography>
                                : <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            }
                        </Grid>
                        <Grid mt={3} xs={12} sm={5}>
                            <Stack rowGap={3} direction="column">
                                <Box>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Arrival Date</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{moment(ServicesValue?.arrival_date?.$d).format('MMM DD, YYYY')}</Typography>
                                </Box>
                                <Box>
                                    <InputLabel sx={{ marginBottom: "10px" }} mt={3} variant="standard">Arrival Time</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{moment(ServicesValue?.arrival_time?.$d).format('hh:mm A')}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid mt={3} xs={12} sm={5}>
                            <Typography variant='h7_2'>Other Chargeable Services (Optional)</Typography><br />
                            {ServicesData?.other_chargeble_Service?.map((item, index) => (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography variant='h9_3' color="text_blue">{item?.title}  -</Typography>
                                    {/* <Typography lineHeight="37px" variant='h9_1' color="green">Included</Typography>0 */}
                                    {otherServices.find((itm, ind) => (itm?.id === item?.id)) ?
                                        <Typography lineHeight="37px" variant='h9_1' color="green">Included</Typography>
                                        : <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                                    }
                                </Stack>
                            ))}
                            {/* <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Tuition Arrangement  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">English IELTS, SAT Training  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">University Course Training  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Counselling  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="green">Included</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography variant='h9_3' color="text_blue">Guardian Services  -</Typography>
                                <Typography lineHeight="37px" variant='h9_1' color="neutral_7">Not Included</Typography>
                            </Stack> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Stack direction="row" alignItems="center" columnGap={10} justifyContent="start">
                        <Typography variant='h7_1'>Personal details</Typography>
                        <Button onClick={() => { setStepperCount(3) }} startIcon={<EditIcon fill="#FFFFFF" />} sx={{ height: "40px", width: "100px", fontSize: "16px", fontWeight: 500 }} variant="standard">Edit</Button>
                    </Stack>
                    <Divider />
                    <Grid container justifyContent="start" rowGap={3}>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Name</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.name}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Nationality</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.nationality}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Passport number</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.passport_no}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Gender</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.gender}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Date of Birth</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{moment(Details?.dob?.$d).format("MMM DD, YYYY")}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">School</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.School}</Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant='h7_3'>Contact</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Contact number</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.contact_no}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">WeChat</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.wechat}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">WhatsApp</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.whatsapp}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Line</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.line}</Typography>
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ marginBottom: "10px" }} variant="standard">KakaoTaalk</InputLabel>
                            <Typography variant='h7_1' color="text_blue">{Details?.kakaotalk}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Stack direction="row" alignItems="center" columnGap={10} justifyContent="start">
                        <Typography variant='h7_1'>Emergency Contact</Typography>
                        <Button onClick={() => { setStepperCount(3) }} startIcon={<EditIcon fill="#FFFFFF" />} sx={{ height: "40px", width: "100px", fontSize: "16px", fontWeight: 500 }} variant="standard">Edit</Button>
                    </Stack>
                    <Divider />
                    {Emergency_contact?.map((item, index) => (
                        <>
                            <Grid xs={12}>
                                <Typography variant='h7_3'>Contact {index + 1}</Typography>
                            </Grid>
                            <Grid container justifyContent="start" rowGap={3}>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Contact name</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_name}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Contact mobile number</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_contact_no}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">Email</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_email}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">WhatsApp</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_whatsapp}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">WeChat</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_wechat}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">LINE</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_line}</Typography>
                                </Grid>
                                <Grid xs={12} sm={5}>
                                    <InputLabel sx={{ marginBottom: "10px" }} variant="standard">KakaoTalk</InputLabel>
                                    <Typography variant='h7_1' color="text_blue">{item?.Emergency_kakaotalk}</Typography>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Stack direction="row" alignItems="center" columnGap={10} justifyContent="start">
                        <Typography variant='h7_1' color="#AD3333">NOTE</Typography>
                        <Button onClick={() => { setStepperCount(3) }} startIcon={<EditIcon fill="#FFFFFF" />} sx={{ height: "40px", width: "100px", fontSize: "16px", fontWeight: 500 }} variant="standard">Edit</Button>
                    </Stack>
                    <Divider />
                    <Grid xs={12} >
                        <Box xs={12} p={2} sx={{ border: "1px solid #1A2461", minHeight: "200px", width: "100%", borderRadius: "8px" }}>
                            <Typography>{Details?.note}</Typography>
                        </Box>
                        <Stack mt={2} gap="10px" direction="row" alignItems="center" justifyContent="start" >
                            <Checkbox />
                            <Typography variant='h9_1' color="text_blue">I agree all the provided Information are correct</Typography>
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>

            <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                <Button variant="standard" onClick={() => { HandleSubmitForm() }}>Continue</Button>
            </Stack>
        </Box >
    )
}
