import React, { useEffect, useRef, useState } from 'react'
import { Typography, Stack, Box, Grid, Divider, InputLabel, Button, TextField, IconButton } from '@mui/material'
import { ReactComponent as AddContactIcon } from "../../../../Icons/AddContactIcon.svg"
import { ReactComponent as UploadFileIcon } from "../../../../Icons/UploadFileIcon.svg"
import { ReactComponent as ImgIcon } from "../../../../Icons/ImgIcon.svg"
import ClearIcon from '@mui/icons-material/Clear';
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs"

export default function Information({ setStepperCount, setPersonalData, PersonalData }) {
    const ipaFile = useRef()
    const { control, handleSubmit, formState: { errors }, getValues, watch, setValue } = useForm({
        mode: "onTouched",
    });

    const initial_Emergency_contact = { Emergency_name: "", Emergency_contact_no: "", Emergency_email: "", Emergency_whatsapp: "", Emergency_wechat: "", Emergency_line: "", Emergency_kakaotalk: "" }

    const { Details, Emergency_contact } = PersonalData

    const Imgwatch = watch("ipa")


    useEffect(() => {
        setValue("ipa", Details?.ipaFile)
    }, [])


    const onSubmit = data => {

        const { name, dob, nationality, gender, passport_no, school, contact_no, wechat, whatsapp, line, kakaotalk, note, ipa } = data

        const Emerdataas = Emergency_contact.map((itm, index) => ({
            Emergency_name: data[`Emergency_name_${index + 1}`],
            Emergency_contact_no: data[`Emergency_contactno_${index + 1}`],
            Emergency_email: data[`Emergency_email_${index + 1}`],
            Emergency_whatsapp: data[`Emergency_whatsapp_${index + 1}`],
            Emergency_wechat: data[`Emergency_wechat_${index + 1}`],
            Emergency_line: data[`Emergency_line_${index + 1}`],
            Emergency_kakaotalk: data[`Emergency_kakaotalk_${index + 1}`]
        }))

        setPersonalData({
            Details: { name: name, dob: dob, nationality: nationality, gender: gender, passport_no: passport_no, school: school, contact_no: contact_no, wechat: wechat, whatsapp: whatsapp, line: line, kakaotalk: kakaotalk, note: note, ipaFile: ipa },
            Emergency_contact: Emerdataas
        })
        setStepperCount(4)
    };

    const handleImage = () => {
        ipaFile.current.click()
    }


    const handleDeleteContact = (ind) => {
        const data = Emergency_contact.filter((itm, index) => (ind !== index))
        setPersonalData((prev) => ({
            ...prev, EmergencyContact: data
        }))
    }
    console.log(Imgwatch);

    console.log(Emergency_contact);
    console.log(PersonalData);


    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Applicant Information</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Personal details</Typography>
                        <Divider />
                        <Grid container justifyContent="space-between">
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Name</InputLabel>
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue={Details?.name}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors.name) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">Name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Date of Bith</InputLabel>
                                <Controller
                                    name="dob"
                                    control={control}
                                    defaultValue={dayjs(Details?.dob)}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <DatePicker error={Boolean(errors.dob) ? true : false} sx={{ width: "100%", '.MuiSvgIcon-root': { fill: "#1A2461" } }} {...field} format="LL" fullWidth />}
                                />

                                {/* {errors.name && <p role="alert">DOB is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Nationality</InputLabel>
                                <Controller
                                    name="nationality"
                                    control={control}
                                    defaultValue={Details?.nationality}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors.nationality) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Gender</InputLabel>
                                <Controller
                                    name="gender"
                                    control={control}
                                    defaultValue={Details?.gender}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors.gender) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Passport number</InputLabel>
                                <Controller
                                    name="passport_no"
                                    control={control}
                                    defaultValue={Details?.passport_no}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors.passport_no) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">School</InputLabel>
                                <Controller
                                    name="school"
                                    control={control}
                                    defaultValue={Details?.school}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors.school) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid mt={2} xs={12}>
                                <Typography variant='h7_1'>Contact</Typography>
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 2 }} variant="standard">Contact Number</InputLabel>
                                <Controller
                                    name="contact_no"
                                    control={control}
                                    defaultValue={Details?.contact_no}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField type="number" error={Boolean(errors?.contact_no) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">WeChat</InputLabel>
                                <Controller
                                    name="wechat"
                                    defaultValue={Details?.wechat}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors?.wechat) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">WhatsApp</InputLabel>
                                <Controller
                                    name="whatsapp"
                                    control={control}
                                    defaultValue={Details?.whatsapp}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors?.whatsapp) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">LINE</InputLabel>
                                <Controller
                                    name="line"
                                    control={control}
                                    defaultValue={Details?.line}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors?.line) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">KakaoTalk</InputLabel>
                                <Controller
                                    name="kakaotalk"
                                    defaultValue={Details?.kakaotalk}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => <TextField error={Boolean(errors?.kakaotalk) ? true : false} {...field} />}
                                />
                                {/* {errors.name && <p role="alert">First name is required</p>} */}
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Emergency contact</Typography>
                        <Divider />
                        {Emergency_contact?.map((item, index) => (
                            <>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant='h7_1'>Contact {index + 1}</Typography>
                                    {index > 0 && <IconButton onClick={() => handleDeleteContact(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                </Stack>
                                <Grid container justifyContent="space-between">
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 3 }} variant="standard">Name</InputLabel>
                                        <Controller
                                            name={'Emergency_name_' + (index + 1)}
                                            control={control}
                                            defaultValue={Emergency_contact[index]?.Emergency_name}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_name_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">Name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 2 }} variant="standard">Contact Number</InputLabel>
                                        <Controller
                                            name={'Emergency_contactno_' + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_contact_no}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field }) => <TextField type="number" error={Boolean(errors[`Emergency_contactno_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 2 }} variant="standard">Email</InputLabel>
                                        <Controller
                                            name={"Emergency_email_" + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_email}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_email_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 3 }} variant="standard">WhatsApp</InputLabel>
                                        <Controller
                                            name={"Emergency_whatsapp_" + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_whatsapp}
                                            control={control}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_whatsapp_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 3 }} variant="standard">WeChat</InputLabel>
                                        <Controller
                                            name={"Emergency_wechat_" + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_wechat}
                                            control={control}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_wechat_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 3 }} variant="standard">LINE</InputLabel>
                                        <Controller
                                            name={"Emergency_line_" + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_line}
                                            control={control}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_line_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>
                                    <Grid xs={12} sm={5}>
                                        <InputLabel sx={{ mt: 3 }} variant="standard">KakaoTalk</InputLabel>
                                        <Controller
                                            name={"Emergency_kakaotalk_" + (index + 1)}
                                            defaultValue={Emergency_contact[index]?.Emergency_kakaotalk}
                                            control={control}
                                            render={({ field }) => <TextField error={Boolean(errors[`Emergency_kakaotalk_${index + 1}`]) ? true : false} {...field} />}
                                        />
                                        {/* {errors.name && <p role="alert">First name is required</p>} */}
                                    </Grid>

                                </Grid>
                            </>
                        ))}
                        <Grid my={5} xs={12}>
                            <Button startIcon={<AddContactIcon fill="#FFFFFF" />} onClick={() => { setPersonalData((prev) => ({ ...prev, Emergency_contact: [...prev[`Emergency_contact`], initial_Emergency_contact] })) }} variant="standard">Add Another Contact</Button>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>NOTE</Typography>
                        <Divider />
                        <Grid container justifyContent="space-between">
                            <Grid xs={12}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Any Allergies to Food or Medication?</InputLabel>
                                <Controller
                                    name="note"
                                    defaultValue={Details?.note}
                                    control={control}
                                    render={({ field }) => <TextField sx={{ '.MuiInputBase-input': { minHeight: "200px" } }} error={Boolean(errors.alergies) ? true : false} {...field} multiline />}
                                />
                                {/* {errors.name && <p role="alert">Name is required</p>} */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Upload IPA</Typography>
                        <Divider />

                        {/* <Box xs={12} onClick={() => ipaFile.current.click()} sx={{ border: Boolean(errors.ipa) ? "1px dashed red" : "1px dashed #1A2461", height: "280px", borderRadius: "8px", backgroundColor: "#F9FBFF" }}>
                            <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: "100px", width: "100px", borderRadius: "100px", backgroundColor: "#F2F2F2", }}>
                                    <UploadFileIcon fill="#1A2461" />
                                </Stack>
                                <Stack mt={2} direction="row" alignItems="center" gap="5px">
                                    <Typography variant='h9_1'>Click to upload</Typography>
                                    <Typography variant='h9_5'>or drag and drop</Typography>
                                </Stack>
                                <Typography variant='h9_5'>Allowed formats - PDF, JPEG, PNG</Typography>
                            </Stack> */}


                        {Boolean(Imgwatch?.length > 0) ?
                            Array.from(Imgwatch)?.map((item, index) => (
                                <Stack mt={2} direction="row" justifyContent="space-between" alignItems="center" padding="23px" borderRadius="8px" sx={{ border: ".5px solid #1A2461" }}>
                                    <Typography variant="h9_1" sx={{ display: "flex", alignItems: "center", gap: "10px" }}><ImgIcon />{item?.name}</Typography>
                                    <ClearIcon onClick={() => { setValue('ipa', null); }} sx={{ color: "#1A2461" }} />
                                </Stack>
                            )) :
                            <Box xs={12} onClick={() => ipaFile.current.click()} sx={{ border: Boolean(errors.ipa) ? "1px dashed red" : "1px dashed #1A2461", height: "280px", borderRadius: "8px", backgroundColor: "#F9FBFF" }}>
                                <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: "100px", width: "100px", borderRadius: "100px", backgroundColor: "#F2F2F2", }}>
                                        <UploadFileIcon fill="#1A2461" />
                                    </Stack>
                                    <Stack mt={2} direction="row" alignItems="center" gap="5px">
                                        <Typography variant='h9_1'>Click to upload</Typography>
                                        <Typography variant='h9_5'>or drag and drop</Typography>
                                    </Stack>
                                    <Typography variant='h9_5'>Allowed formats - PDF, JPEG, PNG</Typography>
                                </Stack>
                            </Box>
                        }


                        <Controller
                            name="ipa"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => <input
                                type="file"
                                onChange={(e) => { field.onChange(e.target.files) }}
                                ref={(e) => {
                                    ipaFile.current = e;
                                }}
                                error={Boolean(errors.ipa) ? true : false} hidden />}
                        />
                        {/* <Controller
                            name="alergies"
                            control={control}
                            render={({ field }) => <TextField sx={{ '.MuiInputBase-root': { minHeight: "200px" } }} error={Boolean(errors.alergies) ? true : false} {...field} multiline />}
                        /> */}
                        {/* {errors.name && <p role="alert">Name is required</p>} */}
                    </Grid>
                </Grid>
                <Stack my={5} direction="row" alignItems="center" justifyContent="space-between">
                    <Button onClick={() => { setStepperCount(2) }} variant="standard">Back</Button>
                    <Button type="submit" variant="standard">Continue</Button>
                </Stack>
            </form>
        </Box >
    )
}
