import React, { useState } from 'react'
import { Typography, Stack, Box, Button } from '@mui/material'
import { ReactComponent as DoubleArrowIcon } from "../../../Icons/DoubleArrowIcon.svg"
import { ReactComponent as CelebrationIcon } from "../../../Icons/CelebrationIcon.svg"
import { ReactComponent as ApplicationProcessFileIcon } from "../../../Icons/ApplicationProcessFileIcon.svg"

const steps = ["Agreement", "Payment", "Success"];

export default function ApplicationSteps({ setFlowCount }) {


    return (
        <>
            <Box padding={{ md: "42px 60px", xs: "30px 20px" }}>
                <Stack gap="7px">
                    <Typography variant="h4_1">Hostel application process</Typography>
                    <Typography variant="h9_4" color="neutral_4">Your application with ours has 6 primary steps.</Typography>
                </Stack>
                <Box mt={5} columnGap={2} rowGap={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 1</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Choose Type</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Student Hostel Type</Typography>
                            <Typography variant="h9_5" color="#CED6FF">Entry Date</Typography>
                            <Typography variant="h9_5" color="#CED6FF">Landing package services & other info</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 2</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Information Form</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Personal information <br /> such as
                                contact type<br />
                                school attended<br />
                                uploadIPA, etc.</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 3</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Confirm Payment</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Only includes deposit<br />
                                deposit, registration fee,<br />
                                supporting fee and others<br />
                                excluding rent fees</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 4</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Pending Confirmation</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Within 24 hours not<br />
                                including weekends</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 5</Typography>
                        <Typography variant="h9_4" color="#FFFFFF">Contract signing &
                            Payment Submission</Typography>
                        <Stack>
                            <Typography variant="h9_5" color="#CED6FF">Confirm and sign the format<br />
                                contract pay rent fees</Typography>
                        </Stack>
                        <Stack height="100%" alignItems="end" justifyContent="end">
                            <DoubleArrowIcon />
                        </Stack>
                    </Stack>
                    <Stack p={2} gap={1} sx={{ boxShadow: "2px 31px 25px -3px rgba(0,0,0,0.08)", width: "215px", height: "215px", backgroundColor: "#122646", borderRadius: "24px" }}>
                        <Typography variant="h9_4" color="#AAAAAA">Step 6</Typography>
                        <Stack height="100%" justifyContent="center" textAlign="center">
                            <Typography variant="h7_1" color="#FFFFFF">Fly to Singapore, Start a new life</Typography>
                        </Stack>
                        <Stack alignItems="end" justifyContent="end">
                            <CelebrationIcon />
                        </Stack>
                    </Stack>
                </Box>
                <Stack mt={15} p="30px" rowGap={2} direction={{ sm: "row", xs: "column" }} justifyContent="space-between" alignItems="center" sx={{ border: ".5px solid #1A2461", borderRadius: "20px" }}>
                    <Stack direction="row" gap={3}>
                        <ApplicationProcessFileIcon />
                        <Stack>
                            <Typography variant="h5">Application Process</Typography>
                            <Typography variant="h9_4">Login to access your application</Typography>
                        </Stack>
                    </Stack>
                    <Button onClick={() => setFlowCount(2)} sx={{ height: "40px" }} variant="standard">Fill form</Button>
                </Stack>
            </Box>
        </>
    )
}
