import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import { ReactComponent as DownArrowIcon } from "../../../../Icons/DownArrow.svg"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';


export default function Agreement({ setStepperCount }) {


    return (

        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Tenancy Agreement</Typography>
            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Divider />
                    <Stack direction="row" alignItems="center" sx={{ fontSize: "20px", fontWeight: 500 }} gap={1}>
                        THIS AGREEMENT is made on the<Typography variant='h7_1'>17th</Typography>day of <Typography variant='h7_1'> March, 2023</Typography>
                    </Stack>
                    <Grid mt={3} container justifyContent="start" columnGap={4}>
                        <Grid xs={12} sm={4} rowGap={2}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>PARTY A</Typography>
                                <InputLabel sx={{ marginBottom: "0px" }} variant="standard">(“the Landlord/Licensor”)</InputLabel>
                            </Stack>
                            <Stack mt={3} direction="column" alignItems="start" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>DR Pacific Holdings Pte Ltd</Typography>
                                <InputLabel sx={{ marginBottom: "0px", color: "#1A2461 !important" }} variant="standard">Vinga Hall <br />
                                    Address: No. 99 Rangoon Road Singapore 218384<br />
                                    UEN: 200821037N</InputLabel>
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={4}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography color="#1A2461" variant='h7_1'>PARTY B</Typography>
                                <InputLabel sx={{ marginBottom: "0px" }} variant="standard">(“the Student”)</InputLabel>
                            </Stack>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Full name</InputLabel>
                            <TextField fullWidth />
                            <InputLabel sx={{ mt: 3 }} variant="standard">Passport Number</InputLabel>
                            <TextField fullWidth />
                            <InputLabel sx={{ mt: 3 }} variant="standard">FIN Number</InputLabel>
                            <TextField fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}  >
                    <Stack textAlign="center" sx={{ border: "1px solid #1A2461", width: "100%", borderRadius: "8px", padding: "40px 60px" }}>
                        <Stack gap={1}>
                            <Typography variant="h6_1">Agreement Terms</Typography>
                            <Typography color="#1A2461" variant="h9_4">The Student will check-in to Vinga Hall, address: 99 Rangoon Road, Singapore 218384, bothparties come into agreed terms as follows:</Typography>
                        </Stack>
                        <Stack mt={5} gap="10px" direction="column" alignItems="start" textAlign="start">
                            <Typography variant='h6_1'>1. PARTY A (Landlord) responsibilities</Typography>
                            <Typography color="#1A2461" variant="h9_4">(1) Party A allows the Student to stay in Vinga Hall address, and promises to monitor on the Student’s activities during the stay,<br />
                                (2) Party A agrees to provide the Student, a room with bed, mattress, wardrobe, bookshelves and writing desk, to ensure the Student stays in a clean, comfortable and conducive environment.<br />
                                (3) Party A reserves the right to allocate and to change room for the Student anytime.<br />
                                (4) Party A offers free Wifi & LAN internet facilities inside the room.<br />
                                (5) Party A provides free air-con, electricity and water supply.<br />
                                (6) Party A provides free laundry services twice weekly.<br />
                                (7) Party A provides daily general room cleaning services and twice weekly specific cleaning.<br />
                                (8) Party A will bring new student to get familiar with the living environment. If the Student is sick, Party A will take initiative to take care of the Student and to inform the Parents, or guardianinSingapore. If needed, assists to bring the Student to see doctor. All transport and doctor consulatation expenses will be borned by the Student.<br />
                                (9) Party A will report to the Parents or guardian in Singapore daily, through Wechat groupchat at around 10pm regarding the Student’s status.<br />
                                (10) Party A will promptly reply the Parents or guardian in Singapore any enquiry related totheStudent’s living condition or his/her status.<br />
                                (11) Party A will manage cash allowance, if the Parents opted for the service, and will distributethe allowance weekly on time with the agreed sum.<br />
                                (12) Party A will provide airticket advanced booking service free of charge.<br />
                                (13) Party A will provide the Student with counseling services on living overseas or educationrelated matters. Facilitate the Student to successfully completed his/her study and to maintain a healthy lifestyle.<br />
                                (14) Party A will provide local school transfer consultation service free of charge, and to give advice to the Student on overseas study matters.<br />
                                (15) Party A will also provide migration consultation service free of charge.</Typography>
                        </Stack>
                    </Stack>
                    <Stack mt={2} gap="5px" direction="row" alignItems="center" justifyContent="start" >
                        <Checkbox />
                        <Typography variant='h9_3' >I have read the contract and I agree with the terms and conditions</Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                <Button onClick={() => { setStepperCount(2) }} variant="standard">Continue</Button>
            </Stack>
        </Box >
    )
}
