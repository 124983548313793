import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import HeaderTop from '../../Components/HeaderTop'
import { useDispatch, useSelector } from "react-redux"
import { HostelGetAll, AnnouncementGetAll, ServicesGetAll } from '../../Redux/Feature/CommonSlice'

export default function Dashboard() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HostelGetAll())
        dispatch(AnnouncementGetAll())
        dispatch(ServicesGetAll())
    }, [])



    return (
        <>
            <HeaderTop />
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}
