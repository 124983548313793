import React, { useState, useRef, useEffect } from 'react'
import { Typography, Box, Divider, Button, Grid, TableContainer, IconButton, Table, TextField, InputLabel, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { ReactComponent as UploadFileIcon } from "../../../Icons/UploadFileIcon.svg"
import { ReactComponent as ImgIcon } from "../../../Icons/ImgIcon.svg"
import ClearIcon from '@mui/icons-material/Clear';
import { json, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios"

export default function HostelAdd() {

    const reactQuillRef = useRef();
    const navigate = useNavigate()

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"], // superscript/subscript
        ],
    };

    const { control, formState: { errors }, setValue, handleSubmit, register, watch } = useForm({
        mode: "onTouched"
    })

    const des = watch("serviceFacility_Image_1")

    const Imgwatch = watch("img")
    const descriptionWatch = watch("description")

    const imgFile = useRef()
    const [Newsdata, setNewsdata] = useState({ title: "", description: "", img: "" })
    const [bgImg, setBgImg] = useState([])

    const initialHostelTypes = { Hostel_type: "", room_type: [{ room_type_name: "", six_month_cont: "", twelve_month_cont: "" }] }

    const [HostelTypes, setHostelTypes] = useState([initialHostelTypes])

    const initialServiceFacility = { img: "", title: "", icon: "", Description: "" }
    const [serviceFacility, setServicesFacility] = useState([initialServiceFacility])

    const [ViewsData, setViewsData] = useState([{ img: "", title: "" }])

    // const [AccessData, setAccessData] = useState([{ title: "", bus_time: "", mrt_time: "", walk_time: "" }])

    console.log(Imgwatch);

    useEffect(() => {
        if (Boolean(Imgwatch)) {
            setBgImg((prev) => ([]))

            Array.from(Imgwatch)?.map((item, index) => (
                setBgImg((prev) => ([...prev, { id: index + 1, img: item }]))
            ))
        }
    }, [Imgwatch])


    const onSubmit = async (data) => {
        console.log("dfsd", data)
        // setNewsdata({ title: data?.title, description: data?.description, img: data?.img })

        // setHostelTypes((prev) => {
        //     const dataas = HostelTypes.map((item, index) => {
        //         const Hostel_type = data[`Hostel_type${index + 1}`]
        //         const subdata = item?.room_type?.map((itm, ind) => ({
        //             room_type_name: data[`room_type_${index + 1}_${ind + 1}`],
        //             six_month_cont: data[`six_month_cont_${index + 1}_${ind + 1}`],
        //             twelve_month_cont: data[`twelve_month_cont_${index + 1}_${ind + 1}`],
        //         }))
        //         return { Hostel_type: Hostel_type, room_type: subdata }
        //     })
        //     return dataas
        // })


        // setServicesFacility((prev) => {
        //     const dataas = serviceFacility.map((itm, index) => {
        //         const sfdata = {
        //             img: data[`serviceFacility_Image_${index + 1}`],
        //             title: data[`serviceFacility_Title_${index + 1}`],
        //             icon: data[`serviceFacility_Icon_${index + 1}`],
        //             Description: data[`serviceFacility_des_${index + 1}`],
        //         }

        //         return sfdata
        //     })
        //     return dataas
        // })

        // setViewsData((prev) => {
        //     const dataas = ViewsData.map((itm, index) => ({
        //         img: data[`Views_Image_${index + 1}`],
        //         title: data[`Views_Title_${index + 1}`],
        //     }))
        //     return dataas
        // })

        // const valuess = [{ id: 1, name: "dfwef", type: [{ type_id: 11, type_name: "fea" }] }, { id: 2, name: "gre", type: [{ type_id: 21, type_name: "fea" }] }]


        const formData = new FormData();

        formData.append("title", data?.title);
        formData.append("description", data?.description);

        bgImg.forEach((item, index) => {
            formData.append(`bgImg[${index}][id]`, item?.id)
            formData.append(`bgImg[${index}][img]`, item?.img)
        })

        // formData.append("img", data?.img?.[0]);

        HostelTypes.forEach((item, index) => {
            formData.append(`HostelTypes[${index}][id]`, index + 1);
            formData.append(`HostelTypes[${index}][Hostel_type]`, data[`Hostel_type${index + 1}`]);
            // formData.append(`HostelTypes[${index}][room_type]`, JSON.stringify(data[`room_type${index + 1}`]));

            item?.room_type.forEach((itm, ind) => {
                formData.append(`HostelTypes[${index}][room_type][${ind}][id]`, ind + 1);
                formData.append(`HostelTypes[${index}][room_type][${ind}][room_type_name]`, data[`room_type_${index + 1}_${ind + 1}`]);
                formData.append(`HostelTypes[${index}][room_type][${ind}][six_month_cont]`, data[`six_month_cont_${index + 1}_${ind + 1}`]);
                formData.append(`HostelTypes[${index}][room_type][${ind}][twelve_month_cont]`, data[`twelve_month_cont_${index + 1}_${ind + 1}`]);
            })
        });

        serviceFacility.forEach((item, index) => {
            formData.append(`serviceFacility[${index}][id]`, index + 1);
            formData.append(`serviceFacility[${index}][img]`, data[`serviceFacility_Image_${index + 1}`]);
            formData.append(`serviceFacility[${index}][title]`, data[`serviceFacility_Title_${index + 1}`]);
            formData.append(`serviceFacility[${index}][icon]`, data[`serviceFacility_Icon_${index + 1}`]);
            formData.append(`serviceFacility[${index}][Description]`, data[`serviceFacility_des_${index + 1}`]);

        });


        ViewsData.forEach((item, index) => {
            formData.append(`Views[${index}][id]`, index + 1);
            formData.append(`Views[${index}][img]`, data[`Views_Image_${index + 1}`]);
            formData.append(`Views[${index}][title]`, data[`Views_Title_${index + 1}`]);
        });



        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}addhostel`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/json'
                }
            });
            navigate('/Admin/Hostellist')
            console.log(response);
        } catch (error) {
            console.log(error);
        }



    }

    const handleAddRoomType = (ind) => {
        setHostelTypes((prev) => {
            const data = HostelTypes.map((itm, index) => {
                if (index === ind) {
                    return ({ ...itm, room_type: [...itm['room_type'], { room_type_name: "", six_month_cont: "", twelve_month_cont: "" }] })
                }
                return itm
            })
            return data
        })
    }


    const handleDeleteHostelType = (ind) => {
        const data = HostelTypes.filter((itm, index) => (ind !== index))
        setHostelTypes(data)
    }


    const handleDeleteRoomType = (ind, subind) => {
        setHostelTypes((prev) => {
            const data = HostelTypes.map((itm, index) => {
                if (index === ind) {
                    const datas = itm?.room_type?.filter((subitm, subindex) => (subind !== subindex))
                    return ({ ...itm, room_type: datas })
                }
                return itm
            })
            return data
        })
    }

    const handleDeleteServicesFacility = (ind) => {
        const data = serviceFacility.filter((itm, index) => (ind !== index))
        setServicesFacility(data)
    }

    const handleDeleteViews = (ind) => {
        const data = ViewsData.filter((itm, index) => (ind !== index))
        setViewsData(data)
    }

    const deleteFirstImage = (id) => {
        let updatedFiles = Array.from(Imgwatch);
        updatedFiles = updatedFiles.filter((file, index) => index !== id);
        setValue('img', updatedFiles);
    };


    return (
        <>
            <Box mb={30}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h7_1">Hostel</Typography>
                </Stack>
                <Divider sx={{ mb: 3, mt: 2 }} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container xs={8} columnGap={4} rowGap={3} justifyContent="space-between">
                        <Grid xs={12}>
                            <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                            <Controller
                                name="title"
                                rules={{ required: false }}
                                control={control}
                                render={({ field }) => <TextField error={Boolean(errors?.title) ? true : false} {...field} />}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <InputLabel sx={{ mb: 1 }}>Location</InputLabel>
                            <Controller
                                name="location"
                                rules={{ required: false }}
                                control={control}
                                render={({ field }) => <TextField error={Boolean(errors?.location) ? true : false} {...field} />}
                            />
                        </Grid>
                        {/* <Grid xs={12} >
                        <InputLabel sx={{ mb: 1 }}>Description</InputLabel>
                        <Controller
                            name="description"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => <TextField sx={{ '.MuiInputBase-input ': { minHeight: "150px" } }} error={Boolean(errors?.description) ? true : false} {...field} multiline />}
                        />
                    </Grid> */}
                        <Grid xs={12} >
                            <InputLabel sx={{ mb: 1 }}>Description</InputLabel>
                            <Controller
                                name="description"
                                rules={{ required: false }}
                                control={control}
                                render={({ field }) => <ReactQuill
                                    style={{ height: "180px" }}
                                    modules={modules}
                                    maxLength={3000}
                                    {...field}
                                />}
                            />

                        </Grid>
                        <Grid mt={5} xs={12} >
                            <InputLabel sx={{ mb: 1 }}>Image</InputLabel>

                            {Boolean(Imgwatch?.length > 0) ?
                                Array.from(Imgwatch)?.map((item, index) => (
                                    <Stack mt={2} direction="row" justifyContent="space-between" alignItems="center" padding="23px" borderRadius="8px" sx={{ border: ".5px solid #1A2461" }}>
                                        <Typography variant="h9_1" sx={{ display: "flex", alignItems: "center", gap: "10px" }}><ImgIcon />{item?.name}</Typography>
                                        <ClearIcon onClick={() => deleteFirstImage(index)} sx={{ color: "#1A2461" }} />
                                    </Stack>
                                )) :
                                <Box xs={12} onClick={() => imgFile.current.click()} sx={{ border: Boolean(errors.img) ? "1px dashed red" : "1px dashed #1A2461", height: "200px", borderRadius: "8px", backgroundColor: "#F9FBFF" }}>
                                    <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                                        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: "100px", width: "100px", borderRadius: "100px", backgroundColor: "#F2F2F2", }}>
                                            <UploadFileIcon fill="#1A2461" />
                                        </Stack>
                                        <Stack mt={2} direction="row" alignItems="center" gap="5px">
                                            <Typography variant='h9_1'>Click to upload</Typography>
                                        </Stack>
                                        <Typography variant='h9_5'>Allowed formats - JPEG, PNG</Typography>
                                    </Stack>
                                </Box>
                            }
                            <Controller
                                name="img"
                                rules={{ required: false }}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => { field.onChange(e.target.files) }}
                                        ref={(e) => {
                                            imgFile.current = e;
                                        }}
                                        hidden
                                        multiple
                                    />
                                )}
                            />
                        </Grid>

                        <Grid xs={12}>
                            <Typography variant="h7_1">Pricing</Typography>
                        </Grid>

                        {HostelTypes?.map((itm, ind) => (
                            <>
                                <Grid xs={12}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <InputLabel sx={{ mb: 1 }}>Hostel Type</InputLabel>

                                        {ind > 0 && <IconButton onClick={() => handleDeleteHostelType(ind)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <Controller
                                        name={`Hostel_type${ind + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Hostel_type${ind + 1}`]) ? true : false} {...field} />}
                                    />
                                </Grid>
                                {itm?.room_type?.map((item, index) => (
                                    <>
                                        <Grid xs={12}>
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <InputLabel sx={{ mb: 1 }}>Room Type</InputLabel>

                                                {index > 0 && <IconButton onClick={() => handleDeleteRoomType(ind, index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                            </Stack>
                                            <Controller
                                                name={`room_type_${ind + 1}_${index + 1}`}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field }) => <TextField error={Boolean(errors[`room_type_${ind + 1}_${index + 1}`]) ? true : false} {...field} />}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={5.5}>
                                            <InputLabel sx={{ mb: 1 }}>6 Month Contract</InputLabel>
                                            <Controller
                                                name={`six_month_cont_${ind + 1}_${index + 1}`}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field }) => <TextField type="number" error={Boolean(errors[`six_month_cont_${ind + 1}_${index + 1}`]) ? true : false} {...field} />}
                                            />
                                        </Grid>
                                        <Grid xs={12} md={5.5}>
                                            <InputLabel sx={{ mb: 1 }}>12 Month Contract</InputLabel>

                                            <Controller
                                                name={`twelve_month_cont_${ind + 1}_${index + 1}`}
                                                rules={{ required: false }}
                                                control={control}
                                                render={({ field }) => <TextField type="number" error={Boolean(errors[`twelve_month_cont_${ind + 1}_${index + 1}`]) ? true : false} {...field} />}
                                            />

                                        </Grid>
                                    </>
                                ))}
                                <Grid my={5} xs={12}>
                                    <Button onClick={() => { handleAddRoomType(ind) }} variant="contained">Add more Room type</Button>
                                </Grid>
                            </>
                        ))}
                        <Grid my={5} xs={12}>
                            <Button onClick={() => { setHostelTypes((prev) => ([...prev, initialHostelTypes])) }} variant="contained">Add more hostel type</Button>
                        </Grid>

                        <Grid xs={12}>
                            <Typography variant="h7_1">Services & Facilities</Typography>
                        </Grid>
                        <Grid xs={12}>

                            {serviceFacility?.map((item, index) => (
                                <>
                                    <Stack mt={5} direction="row" alignItems="center" justifyContent="space-between">
                                        <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                                        {index > 0 && <IconButton onClick={() => handleDeleteServicesFacility(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <Controller
                                        name={`serviceFacility_Title_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`serviceFacility_Title_${index + 1}`]) ? true : false} {...field} />}
                                    />

                                    <InputLabel sx={{ mb: 1, mt: 3 }}>Description</InputLabel>
                                    <Controller
                                        name={`serviceFacility_des_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => (
                                            <ReactQuill
                                                style={{ height: "180px" }}
                                                modules={modules}
                                                {...field}
                                            />
                                        )}
                                    />

                                    <InputLabel sx={{ mb: 1, mt: 7 }}>Image</InputLabel>
                                    <Controller
                                        name={`serviceFacility_Image_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => { field.onChange(e.target.files[0]) }}
                                            />
                                        )}
                                    />

                                    <InputLabel sx={{ mb: 1, mt: 3 }}>Icon</InputLabel>
                                    <Controller
                                        name={`serviceFacility_Icon_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => field.onChange(e.target.files[0])}
                                            />
                                        )}
                                    />
                                </>
                            ))}
                        </Grid>
                        <Grid my={5} xs={12}>
                            <Button onClick={() => { setServicesFacility((prev) => ([...prev, initialServiceFacility])) }} variant="contained">Add more Servies & Facility</Button>
                        </Grid>

                        <Grid xs={12}>
                            <Typography variant="h7_1">Views</Typography>
                        </Grid>
                        <Grid xs={12}>
                            {ViewsData?.map((item, index) => (
                                <>
                                    <Stack mt={5} direction="row" alignItems="center" justifyContent="space-between">
                                        <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                                        {index > 0 && <IconButton onClick={() => handleDeleteViews(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <Controller
                                        name={`Views_Title_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Views_Title_${index + 1}`]) ? true : false} {...field} />}
                                    />

                                    <InputLabel sx={{ mb: 1, mt: 2 }}>Image</InputLabel>
                                    <Controller
                                        name={`Views_Image_${index + 1}`}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => { field.onChange(e.target.files[0]) }}
                                            />
                                        )}
                                    />
                                </>
                            ))}
                        </Grid>
                        <Grid my={5} xs={12}>
                            <Button onClick={() => { setViewsData((prev) => ([...prev, { img: "", title: "" }])) }} variant="contained">Add more Views</Button>
                        </Grid>
                        {/* 
                        <Grid xs={12}>
                            <Typography variant="h7_1">Access</Typography>
                        </Grid>
                        {AccessData?.map((item, index) => (
                            <>
                                <Grid xs={12}>
                                    <Stack mt={5} direction="row" alignItems="center" justifyContent="space-between">
                                        <InputLabel sx={{ mb: 1 }}>Title</InputLabel>
                                        {index > 0 && <IconButton onClick={() => handleDeleteAccess(index)}> <ClearIcon sx={{ color: "#f37777", fontSize: "30px" }} /></IconButton>}
                                    </Stack>
                                    <Controller
                                        name={`Views_Title_${index + 1}`}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Views_Title_${index + 1}`]) ? true : false} {...field} />}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <InputLabel sx={{ mb: 1, mt: 2 }}>Bus</InputLabel>
                                    <Controller
                                        name={`Views_bus_${index + 1}`}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Views_bus_${index + 1}`]) ? true : false} {...field} />}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <InputLabel sx={{ mb: 1, mt: 2 }}>MRT</InputLabel>
                                    <Controller
                                        name={`Views_mrt_${index + 1}`}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Views_mrt_${index + 1}`]) ? true : false} {...field} />}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <InputLabel sx={{ mb: 1, mt: 2 }}>WALK</InputLabel>
                                    <Controller
                                        name={`Views_walk_${index + 1}`}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => <TextField error={Boolean(errors[`Views_walk_${index + 1}`]) ? true : false} {...field} />}
                                    />
                                </Grid>
                            </>
                        ))}
                        <Grid my={5} xs={12}>
                            <Button onClick={() => { setAccessData((prev) => ([...prev, { title: "", bus_time: "", mrt_time: "", walk_time: "" }])) }} variant="contained">Add more Access</Button>
                        </Grid> */}

                        <Grid mt={3} xs={12} container justifyContent="end">
                            <Button variant="contained" type="submit">Confirm</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
