import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Button } from '@mui/material'
import ConfirmationApplIcon from "../../../../Icons/ConfirmationAppl.svg"

export default function Confirmation({ setStepperCount }) {
    return (
        <>
            <Box px={{ md: 10, xs: 3 }}>
                <Typography variant="h6_1">Application Confirmation</Typography>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Divider />
                        <Stack my={10} direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                            <Box mb={3} component="img" src={ConfirmationApplIcon}></Box>
                            <Typography variant="h6_1">Application Confirmation</Typography>
                            <Typography variant='h7_2' >We will get back to you within 24 hours (excluding weekends)</Typography>
                            <Button sx={{ mt: 3 }} variant="standard" onClick={() => { setStepperCount(4) }}>View application</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}
