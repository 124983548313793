import React, { useState, useEffect } from 'react'
import { Typography, Box, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import parse from "html-react-parser";
import axios from "axios"
import moment from "moment"


export default function Application() {

    const navigate = useNavigate()

    const [announcementData, setAnnouncementData] = useState([])

    const getAllAnnouncement = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllApplication`)
            console.log(response?.data?.data);
            setAnnouncementData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllAnnouncement()
    }, [])


    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h7_1">Applications</Typography>
                {/* <Button onClick={() => navigate("AnnouncementAdd")} variant="contained">Add</Button> */}
            </Stack>
            <Divider sx={{ mb: 3, mt: 2 }} />
            <TableContainer sx={{ borderRadius: "8px", }}>
                <Table sx={{ minWidth: 650, }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h9_1" color='#333333'>Name</Typography>
                            </TableCell>
                            <TableCell> <Typography variant="h9_1" color='#333333'>Gender</Typography></TableCell>
                            <TableCell> <Typography variant="h9_1" color='#333333'>HostelName</Typography></TableCell>
                            <TableCell> <Typography variant="h9_1" color='#333333'>Date & Time</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {announcementData.map((item, index) => (
                            <TableRow key={item.name} sx={{ borderTop: ".5px solid #737373" }} >
                                <TableCell ><Typography variant="h9_4" color='#333333'>{item?.PersonalData?.Details?.name || "-"}</Typography></TableCell>
                                <TableCell ><Typography variant="h9_4" color='#333333'>{item?.PersonalData?.Details?.gender || "-"}</Typography></TableCell>
                                <TableCell ><Typography variant="h9_4" color='#333333'>{item?.RoomsData?.selectedHostelRooms?.HostelName || "_"}</Typography></TableCell>
                                <TableCell ><Typography variant="h9_4" color='#333333'>{moment(item?.created_at).format('MMM DD YYYY , hh:mm A') || "-"}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
