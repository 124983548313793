import { ReactComponent as LogoIcon } from '../../Icons/logo.svg';
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography, Grid, Stack } from "@mui/material"
import { ReactComponent as NewsMenuIcon } from "../../Icons/Admin/NewsMenuIcon.svg"
import { ReactComponent as AnnouncementMenuIcon } from "../../Icons/Admin/AnnouncementMenuIcon.svg"
import { ReactComponent as HostelIcon } from "../../Icons/Admin/HostelIcon.svg"


export default function Dashboard() {
    const navigate = useNavigate()
    const [renderBlock, setRenderBlock] = useState(1)

    return (
        <>
            <Box sx={{ height: "100vh", overflow: "auto" }}>
                <Box sx={{ width: "100%", height: "120px", backgroundColor: "black", backgroundColor: "primary.main", display: 'flex', alignItems: "center", justifyContent: "space-between", px: { md: "60px", xs: "20px" } }}>
                    <Box>
                        <LogoIcon />
                    </Box>
                </Box>
                <Box sx={{ position: "fixed", width: "100%" }}>
                    <Grid container pt={2} pb={3} >
                        <Grid xs={12} px={{ md: 7, xs: 4 }} pb={2} borderBottom='.5px solid #737373'>
                            <Typography variant="h6_1">Admin Dashboard</Typography>
                        </Grid>
                        <Grid lg={2.1} px={{ md: 4, xs: 4 }} md={3} sm={4} xs={12} height="100vh" borderRight='.5px solid #737373'>
                            <Stack direction="column" gap={2} py={4} px={2}>
                                <Stack onClick={() => { navigate("/Admin"); setRenderBlock(1) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 1 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 1 ? "#1A2461" : "#737373" }}>
                                    <IconButton><AnnouncementMenuIcon fill={renderBlock == 1 ? "#1A2461" : "#737373"} /></IconButton>
                                    <Typography variant="h9_1">Announcement</Typography>
                                </Stack>
                                <Stack onClick={() => { navigate("NewsList"); setRenderBlock(2) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 2 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 2 ? "#1A2461" : "#737373" }}>
                                    <IconButton><NewsMenuIcon fill={renderBlock == 2 ? "#1A2461" : "#737373"} /></IconButton>
                                    <Typography variant="h9_1">News</Typography>
                                </Stack>
                                <Stack onClick={() => { navigate("Hostellist"); setRenderBlock(3) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 3 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 3 ? "#1A2461" : "#737373" }}>
                                    <IconButton><HostelIcon fill={renderBlock == 3 ? "#1A2461" : "#737373"} /></IconButton>
                                    <Typography variant="h9_1">Hostel</Typography>
                                </Stack>
                                <Stack onClick={() => { navigate("Application"); setRenderBlock(4) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 4 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 4 ? "#1A2461" : "#737373" }}>
                                    <IconButton><HostelIcon fill={renderBlock == 4 ? "#1A2461" : "#737373"} /></IconButton>
                                    <Typography variant="h9_1">Application</Typography>
                                </Stack>
                                <Stack onClick={() => { navigate("Services"); setRenderBlock(5) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 5 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 5 ? "#1A2461" : "#737373" }}>
                                    <IconButton><HostelIcon fill={renderBlock == 5 ? "#1A2461" : "#737373"} /></IconButton>
                                    <Typography variant="h9_1">Services</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid py={4} px={3} lg={9.9} md={9} sm={8} xs={12} >
                            <Box sx={{ overflowY: "scroll", height: "100vh", "::-webkit-scrollbar": { display: "none" }, }}>
                                <Outlet />
                            </Box>
                            {/* {renderBlock === 1 && <AnnouncementList />} */}
                            {/* {renderBlock === 1 && <Details />}
                        {renderBlock === 2 && <Application />}
                        {renderBlock === 3 && <Checklist />}
                        {renderBlock === 4 && <Liked />}
                        {renderBlock === 5 && <Preference />} */}
                        </Grid>
                    </Grid>
                </Box >
            </Box>
        </>
    )
}
