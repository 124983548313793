import React, { useState } from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import { ReactComponent as DownArrowIcon } from "../../../../Icons/DownArrow.svg"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useSelector } from 'react-redux';
import parse from "html-react-parser"
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

export default function Services({ setStepperCount, setServicesValue, ServicesValue }) {

    const { ServicesData } = useSelector((state) => (state.CommonData))
    const { formState: { errors }, control, handleSubmit, watch } = useForm({
        mode: "onTouched"
    })


    const { otherServices, fligt_no, arrival_date, arrival_time, landingServices } = ServicesValue


    const handleChangeOtherServices = (e, item) => {
        const { checked, name } = e.target
        const findData = ServicesData?.other_chargeble_Service?.filter((itm, index) => (itm?.id === item?.id))
        const removeData = otherServices.filter((itm, index) => (itm?.id !== item?.id))

        if (name == "other") {
            if (checked) {
                setServicesValue((prev) => ({ ...prev, otherServices: [...prev[`otherServices`], findData[0]] }))
            }
            else {
                setServicesValue((prev) => ({ ...prev, otherServices: removeData }))
            }
        }
        else if (name == "landing_Services_Check") {
            setServicesValue((prev) => ({
                ...prev,
                landingServices: checked ? ServicesData?.landing_services_package : "",
            }))
        }
    }


    const onSubmit = async (data) => {
        console.log("dfsd", data)

        setServicesValue((prev) => ({
            ...prev,
            fligt_no: data?.fligt_no,
            arrival_date: data?.arrival_date,
            arrival_time: data?.arrival_time,
        }))

        setStepperCount(3)
    }



    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <Box px={{ md: 10, xs: 3 }}>
                <Typography variant="h6_1">Select Preferred room</Typography>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Flight details</Typography>
                        <Divider />
                        <Grid container justifyContent="space-between">
                            <Grid xs={12} sm={4}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Flight number</InputLabel>
                                <Controller
                                    name="fligt_no"
                                    rules={{ required: false }}
                                    defaultValue={fligt_no}
                                    control={control}
                                    render={({ field }) => <TextField error={Boolean(errors?.title) ? true : false} {...field} />}
                                />
                            </Grid>
                            <Grid xs={12} sm={3.3}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Arrival date</InputLabel>
                                <Controller
                                    name="arrival_date"
                                    defaultValue={Boolean(arrival_time) ? dayjs(arrival_date) : null}
                                    rules={{ required: false }}
                                    control={control}
                                    render={({ field }) => <DatePicker  {...field} sx={{ width: "100%", '.MuiSvgIcon-root': { fill: "#1A2461" } }} format="LL" fullWidth />}
                                />
                            </Grid>
                            <Grid xs={12} sm={2.2}>
                                <InputLabel sx={{ mt: 3 }} variant="standard">Arrival time</InputLabel>
                                <Controller
                                    name="arrival_time"
                                    defaultValue={Boolean(arrival_time) ? dayjs(arrival_time) : null}
                                    rules={{ required: false }}
                                    control={control}
                                    render={({ field }) => <TimeField  {...field} sx={{ width: "100%" }} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Landing Services (Optional)</Typography>
                        <Divider />
                        <Stack gap="10px" direction="row" justifyContent="space-between" alignItems="center" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                            {/* <Controller
                                name="landing_Services_Check"
                                rules={{ required: false }}
                                control={control}
                                render={({ field }) => <Checkbox checked={Boolean(landingServices)}  {...field} />}
                            /> */}
                            <Checkbox name="landing_Services_Check" checked={Boolean(landingServices)} onChange={(e) => handleChangeOtherServices(e)} />
                            <Stack>
                                <Typography variant='h9_1' color="text_blue">Landing Service Package</Typography>
                                <Typography variant='h9_4' >{Boolean(ServicesData?.landing_services_package?.name) && parse(ServicesData?.landing_services_package?.name)}</Typography>
                            </Stack>
                            <Typography flexGrow={1} textAlign="end" variant='h6_1' color="neutral_3">S$ {ServicesData?.landing_services_package?.amount}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Typography variant='h7_1'>Other Chargeable Services (Optional)</Typography>
                        <Divider />
                        {ServicesData?.other_chargeble_Service?.map((item, index) => (
                            <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                                <Checkbox name="other" checked={otherServices.filter((itm, ind) => itm?.id === item?.id).length === 0 ? false : true} onChange={(e) => handleChangeOtherServices(e, item)} />
                                < Typography variant='h9_1' color="text_blue">{item?.title}</Typography>
                            </Stack>
                        ))}
                        {/* <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">Tuition Arrangement</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">English IELTS, SAT Training</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">University Course Training</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">Counselling</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Stack>
                            <Typography variant='h9_1' color="text_blue">Guardian Service</Typography>
                            <Typography variant='h9_4' > Primary and secondary schools and international schools $500/ month Private universities $2000/ year.</Typography>
                        </Stack>
                    </Stack> */}
                    </Grid>
                </Grid>
                <Stack my={5} direction="row" alignItems="center" justifyContent="space-between">
                    <Button onClick={() => { setStepperCount(1) }} variant="standard">Back</Button>
                    <Button type="submit" variant="standard">Continue</Button>
                </Stack>
            </Box >
        </form>

    )
}
