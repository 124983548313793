import React, { useState } from 'react'
import { Typography, Stack, Box, Stepper, Step, StepLabel, StepButton, setStepperCount } from '@mui/material'
import { ReactComponent as StepperCorrectIcon } from "../../../../Icons/StepperCorrectIcon.svg"
import Agreement from './Agreement';
import Payment from './Payment';
import Success from './Success';

const steps = ["Agreement", "Payment", "Success"];

export default function Contract() {

    const [stepperCount, setStepperCount] = useState(3)

    const StepperIcon = (index) => {
        if ((stepperCount > index + 1) || (steps.length == stepperCount)) {
            return <StepperCorrectIcon />
        }
        else if (stepperCount == index + 1) {
            return <Typography width="36px" height="36px" backgroundColor="text_blue" borderRadius="100px" color="white" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
        else {
            return <Typography width="36px" height="36px" backgroundColor="white" border=".5px solid #737373" borderRadius="100px" color="#737373" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
    }


    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" padding="42px 60px">
                    <Stack gap="10px">
                        <Typography variant="h4_1">Hostel application process</Typography>
                        <Typography variant="h9_4" color="neutral_4">Fill in the required details</Typography>
                    </Stack>
                    <Typography variant="h9_4" color="neutral_4">Saved</Typography>
                </Stack>
                <Box px={8} py={3} mb={5} sx={{ width: '100%', display: "flex", justifyContent: "center", borderTop: ".5px solid #1A2461", borderBottom: ".5px solid #1A2461" }}>
                    <Stepper nonLinear activeStep={1} sx={{ width: { md: '50%', xs: "100%" } }}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" icon={StepperIcon(index)}>
                                    <Typography variant="h8_1" color={((stepperCount == index + 1) && (steps.length != stepperCount)) ? "text_blue" : ((stepperCount > index + 1) || (steps.length == stepperCount)) ? "green" : "neutral_4"}> {label}</Typography>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {stepperCount === 1 && <Agreement setStepperCount={setStepperCount} />}
                {stepperCount === 2 && <Payment setStepperCount={setStepperCount} />}
                {stepperCount === 3 && <Success setStepperCount={setStepperCount} />}
            </Box>
        </>
    )
}
