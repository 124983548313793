import React from 'react'
import { Typography, Stack, CardContent, Card, CardActionArea, CardMedia, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import { ReactComponent as RightUpArrowIcon } from "../../../Icons/rightUpArrowWhite.svg"

export default function Liked() {
    return (
        <>
            <Box mb={1} >
                <Typography variant="h7_1">Liked</Typography>
            </Box>
            <Divider />
            <Grid mt={3} container columnGap={4} height="fit-content">
                <Grid xs={12} md={5}>
                    <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image="https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600"
                                alt="green iguana"
                            />
                            <CardContent sx={{ padding: "0", marginTop: "10px" }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                        Title of News
                                    </Typography>
                                    <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                </Stack>
                                <Typography variant="h9_4">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <Stack direction="row" justifyContent="end">
                            <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                        </Stack>
                    </Card>
                </Grid>
                <Grid xs={12} md={5}>
                    <Card sx={{ maxWidth: 412, p: 2, borderRadius: "20px", textAlign: "start", position: "relative", backgroundColor: "#EFF4FC" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image="https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600"
                                alt="green iguana"
                            />
                            <CardContent sx={{ padding: "0", marginTop: "10px" }}>
                                <Typography gutterBottom variant="h7_1" component="div" >Vinga Hall @ RG</Typography>
                                <Typography variant="h9_1">About the Hostel</Typography><br />
                                <Typography variant="h9_4"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</Typography>
                            </CardContent>
                        </CardActionArea>
                        <Stack mt={2} direction="row" justifyContent="start" alignItems='end' height="100%">
                            <Button variant="contained" endIcon={<RightUpArrowIcon />} sx={{ width: "100px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>View</Button>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
