import React, { useState } from 'react'
import { Box, Grid, Stack, Typography } from "@mui/material"


export default function OurServices() {

    const [data, setData] = useState([
        {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Airport Pickup",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        },
        {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Medical Checkup",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Pass collection",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Bank Card",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Travel card",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Phone card",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Extra curricular activities",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Tuition",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "IELTS, SAT training",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Course training",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Counselling",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        }, {
            img: "https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600",
            title: "Guardian Service",
            des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        },
    ])

    return (
        <>
            <Box mt={17}>
                <Stack mb={5} alignItems="center" textAlign="center" gap={4}>
                    <Typography variant="h7_3" color="#1A2461">We offer services to the students to which removes all the difficulties <br /> in moving to Singapore and start studying</Typography>

                    <Typography p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">OUR SERVICES</Typography>
                </Stack>
                <Grid container columnGap={4} rowGap={4} justifyContent="center">
                    {data?.map((item, index) => (
                        <Grid xs={12} sm={6} md={3} border=".5px solid #A9A9A9" borderRadius="10px" sx={{ boxShadow: "-2px 25px 23px -7px rgba(0,0,0,0.1)" }}>
                            <Stack direction="row" >
                                <Box component="img" src={item?.img} sx={{ width: "180px", height: "180px", borderRadius: "10px", objectFit: "cover" }}></Box>
                                <Stack p={2} justifyContent="center" gap="10px">
                                    <Typography variant="h9_1" >{item?.title}</Typography>
                                    <Typography variant="h9_5" >{item?.des}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    )
}
