import React, { useState } from 'react'
import { Typography, Box, Grid, Stack, TextField, Button, InputAdornment } from '@mui/material'
import { ReactComponent as LogoIcon } from '../Icons/logo.svg';
import { ReactComponent as MailIcon } from '../Icons/MailIcon.svg';
import { ReactComponent as PasswordIcon } from '../Icons/PasswordIcon.svg';
import { ReactComponent as LeftArrowLgIcon } from '../Icons/LeftArrowLg.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SuccessPage from './SuccessPage';


export default function LandingPage() {

    const [displayScreen, setDisplayScreen] = useState("login")

    return (
        <Box height="100vh">
            <Box mb={6} sx={{ width: "100%", height: "120px", backgroundColor: "black", backgroundColor: "primary.main", display: 'flex', alignItems: "center", justifyContent: "space-between", px: { md: "60px", xs: "20px" } }}>
                <Box>
                    <LogoIcon />
                </Box>
            </Box>
            <Typography px={{ sm: 10, xs: 2 }} sx={{ display: "flex", alignItems: "center", gap: "15px" }} variant="h9_1" color="#003893"><LeftArrowLgIcon />Back to Homepage</Typography>
            <Grid mt={5} pb={5} px={2} container justifyContent="center" alignItems="center">
                {displayScreen === "login" && <Login setDisplayScreen={setDisplayScreen} />}
                {displayScreen === "forgotpassword" && <ForgotPassword setDisplayScreen={setDisplayScreen} />}
                {displayScreen === "resetpassword" && <ResetPassword setDisplayScreen={setDisplayScreen} />}
                {displayScreen === "mailsent" && <SuccessPage setDisplayScreen={setDisplayScreen} />}
            </Grid>
        </Box>
    )
}
