import React, { useState, useEffect } from 'react'
import { Typography, Box, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import parse from "html-react-parser";

export default function NewsList() {

    const navigate = useNavigate()
    const [NewsData, setNewsData] = useState([])

    const getAllNews = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllNews`);
            console.log(response);
            setNewsData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        getAllNews()
    }, [])

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h7_1">News</Typography>
                <Button onClick={() => navigate("/Admin/NewsAdd")} variant="contained">Add</Button>
            </Stack>
            <Divider sx={{ mb: 3, mt: 2 }} />
            <TableContainer sx={{ borderRadius: "8px", }}>
                <Table sx={{ minWidth: 650, }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell> <Typography variant="h9_1" color='#333333'>Image</Typography></TableCell>
                            <TableCell><Typography variant="h9_1" color='#333333'>Title</Typography></TableCell>
                            <TableCell> <Typography variant="h9_1" color='#333333'>Description</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {NewsData.map((item, index) => (
                            <TableRow key={item.name} sx={{ borderTop: ".5px solid #737373" }}>
                                <TableCell ><Box component="img" src={process.env.REACT_APP_FILE_PATH + item?.img} sx={{ width: "250px" }}></Box></TableCell>
                                <TableCell sx={{ minWidth: "200px" }} ><Typography variant="h9_4" color='#333333'>{item?.title || "-"}</Typography></TableCell>
                                <TableCell ><Typography variant="h9_4" color='#333333'>{Boolean(item?.description) ? parse(item?.description) : ""}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
