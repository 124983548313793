import React, { useState } from 'react'
import { Typography, Stack, Box, Stepper, Step, StepLabel, StepButton, setStepperCount } from '@mui/material'
import { ReactComponent as StepperCorrectIcon } from "../../../../Icons/StepperCorrectIcon.svg"
import PenaltyTerms from './PenaltyTerms';
import AdminApproval from './AdminApproval';
import DepositReturn from './DepositReturn';
import Confirmation from './Confirmation';


const RejectedSteps = ["Penalty terms", "Admin approval"];

const ApprovedSteps = ["Penalty terms", "Admin approval", "Deposit Return", "Confirmation"];


export default function BreakContact() {

    const [stepperCount, setStepperCount] = useState(3)

    const StepperIcon = (index) => {
        if (stepperCount > index + 1) {
            return <StepperCorrectIcon />
        }
        else if (stepperCount == index + 1) {
            return <Typography width="36px" height="36px" backgroundColor="text_blue" borderRadius="100px" color="white" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
        else {
            return <Typography width="36px" height="36px" backgroundColor="white" border=".5px solid #737373" borderRadius="100px" color="#737373" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
    }


    return (
        <>
            <Box>
                <Stack padding="42px 60px">
                    <Typography variant="h4_1">Break Contract</Typography>
                    <Typography variant="h9_4" color="neutral_4">Proceed with further steps</Typography>
                </Stack>
                <Box px={8} py={3} mb={5} sx={{ display: "flex", justifyContent: "center", width: '100%', borderTop: ".5px solid #1A2461", borderBottom: ".5px solid #1A2461" }}>
                    <Stepper nonLinear activeStep={1} sx={{ width: "70%" }}>
                        {ApprovedSteps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" icon={StepperIcon(index)}>
                                    <Typography variant="h8_1" color={(stepperCount == index + 1) ? "text_blue" : (stepperCount > index + 1) ? "green" : "neutral_4"}> {label}</Typography>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {stepperCount === 1 && <PenaltyTerms setStepperCount={setStepperCount} />}
                {stepperCount === 2 && <AdminApproval setStepperCount={setStepperCount} />}
                {stepperCount === 3 && <DepositReturn setStepperCount={setStepperCount} />}
                {stepperCount === 4 && <Confirmation setStepperCount={setStepperCount} />}

            </Box>
        </>
    )
}
