import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Button, CardActionArea, Grid, Box, IconButton, Card, CardContent, CardMedia, Typography, Stack } from '@mui/material';
import { ReactComponent as ShareNewsIcon } from "../Icons/shareNewsIcon.svg"
import { ReactComponent as FavouritesNewsIcon } from "../Icons/favouritesNewsIcon.svg"
import parse from "html-react-parser";
import { useNavigate, useParams } from 'react-router-dom';




const DemoCourosel = ({ NewsData }) => {
    const navigate = useNavigate()


    const images = [
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },

    ];


    const styles = {
        controlDots: {
            margin: '118px 0 !important'
        },
        selectedDot: {
            width: '36px',
            height: '8px',
            borderRadius: '24px',
            background: "#434343",
            margin: '0 4px'
        },
        UnselectedDot: {
            width: '8px',
            height: '8px',
            borderRadius: '24px',
            background: "#FFFFFF",
            border: "1px solid #434343",
            margin: '0 4px'
        }
    };


    return (
        <Box mt={5} className="Newscaurosel" sx={{ px: "5%", pt: 7, background: "#EFF4FC" }}>
            <Carousel id="news"
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                useKeyboardArrows
                centerMode
                centerSlidePercentage={34.99} // This will ensure 3 items are shown (100% / 3 = 33.33%)
                emulateTouch
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const indicatorStyle = isSelected ? styles.selectedDot : styles.UnselectedDot
                    return (
                        <li
                            style={{ ...indicatorStyle, display: 'inline-block', cursor: 'pointer' }}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
            >

                {NewsData?.map((itm, index) => (
                    <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                        <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                            <ShareNewsIcon />
                            <FavouritesNewsIcon />
                        </Stack>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={process.env.REACT_APP_FILE_PATH + itm?.img}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                        {itm?.title}
                                    </Typography>
                                    <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                </Stack>
                                <Typography variant="h9_4">
                                    {Boolean(itm?.description) ? parse(itm?.description) : ""}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <Stack direction="row" justifyContent="end">
                            <Button onClick={() => navigate(`/news/${itm?._id}`)} variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                        </Stack>
                    </Card>
                ))}
            </Carousel>
        </Box>
    );
};

export default DemoCourosel;

