import React from 'react'
import { Typography, Stack, Box, Grid, Divider, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import BreakFormIcon from "../../../../Icons/Account/BreakForm.svg"


export default function Confirmation({ setStepperCount }) {
    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Confirmation</Typography>
            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Divider />
                    <Stack my={10} direction="column" justifyContent="space-between" alignItems="center" gap={1}>
                        <Box mb={3} component="img" src={BreakFormIcon}></Box>
                        <Typography variant="h6_1">Contract Broken</Typography>
                        <Typography variant='h7_2' >Deposit amount sent to bank</Typography>
                        <Typography sx={{ fontSize: "20px", fontWeight: 300, fontStyle: "italic" }}>Refund May take about 7 days to reflect to your bank account</Typography>
                        <Button sx={{ mt: 3 }} variant="standard" onClick={() => { setStepperCount(3) }}>Go to home</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}
