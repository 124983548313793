import React from 'react'
import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem } from '@mui/material'
import { useForm, Controller } from "react-hook-form";
import { emailRegex } from '../../Utils';


export default function ContactUs() {
    const { control, handleSubmit, formState: { errors }, getValues, watch } = useForm({
        mode: "onTouched",
    });

    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <>
            <Box py={3} px={{ md: 12, xs: 3 }} >
                <Stack my={7} alignItems="center" textAlign="center" gap={4}>
                    <Typography p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">STILL GOT A QUESTION? CONTACT US</Typography>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="space-between">
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Name</InputLabel>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => <TextField error={Boolean(errors.name) ? true : false} {...field} />}
                            />
                            {/* {errors.name && <p role="alert">Name is required</p>} */}
                        </Grid>
                        <Grid xs={12} sm={5}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Email</InputLabel>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Email is Required",
                                    validate: (value) => emailRegex.test(value) == true || "Invalid Email"
                                }}
                                render={({ field }) => <TextField error={Boolean(errors.email) ? true : false} {...field} />}
                            />
                            {Boolean(errors?.email?.message) && <Typography sx={{ color: "red", my: 1 }} variant="h9_4">{errors?.email?.message}</Typography>}

                        </Grid><Grid xs={12} sm={5}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Mobile Number</InputLabel>
                            <Controller
                                name="mobile_no"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => <TextField type="number" error={Boolean(errors.mobile_no) ? true : false} {...field} />}
                            />
                            {/* {errors.name && <p role="alert">Name is required</p>} */}
                        </Grid><Grid xs={12} sm={5}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Query Subject</InputLabel>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => <TextField error={Boolean(errors.subject) ? true : false} {...field} />}
                            />
                            {/* {errors.name && <p role="alert">Name is required</p>} */}
                        </Grid>
                        <Grid xs={12} >
                            <InputLabel sx={{ mt: 3 }} variant="standard">Message/Query</InputLabel>
                            <Controller
                                name="message"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => <TextField sx={{ '.MuiInputBase-input ': { minHeight: "150px" } }} error={Boolean(errors.message) ? true : false} {...field} multiline />}
                            />
                            {/* {errors.name && <p role="alert">Name is required</p>} */}

                        </Grid>
                        <Grid xs={12} container justifyContent="center">
                            <Button type="submit" sx={{ mt: 5 }} variant="contained">Send Message</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
