import React, { useState, useEffect } from 'react'
import { Box, Grid, Stack, Typography, Button, IconButton } from "@mui/material"
import Carousel from "../Carousel"
import { ReactComponent as LeftArrowIcon } from "../../Icons/leftArrow.svg"
import { ReactComponent as RightArrowIcon } from "../../Icons/rightArrow.svg"
import { ReactComponent as ApplicationIcon } from "../../Icons/application.svg"
import CustomCardCarousel from '../CardCourosel'
import DemoCourosel from '../DemoCourosel'
import axios from "axios"
import { useNavigate } from 'react-router-dom'

export default function News() {
    const navigate = useNavigate()
    const [NewsData, setNewsData] = useState([])

    const getAllNews = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getAllNews`);
            console.log(response);
            setNewsData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        getAllNews()
    }, [])


    return (
        <>
            <Box mt={10} width="100%">
                <Stack px={5} direction="row" justifyContent="space-between">
                    <Box></Box>
                    <Typography p={2} variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">NEWS</Typography>
                    <Stack columnGap="20px" direction="row" justifyContent="space-between">
                        <Box sx={{ width: "40px", height: "40px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <LeftArrowIcon />
                        </Box>
                        <Box sx={{ width: "40px", height: "40px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <RightArrowIcon />
                        </Box>
                    </Stack>
                </Stack>
                {/* <CustomCardCarousel /> */}
                <DemoCourosel NewsData={NewsData} />
                <Stack pb={5} pt={2} direction="row" justifyContent="center" sx={{ backgroundColor: "#EFF4FC" }}>
                    <Typography onClick={() => navigate(`/news`)} variant="h9_3" sx={{ width: "124px", height: "40px", backgroundColor: "#27BAC4", padding: "0px 10px 0px 20px", color: "white", borderRadius: "50px", display: "flex", justifyContent: "space-around", alignItems: "center" }}>All News <RightArrowIcon /></Typography>
                </Stack>
            </Box>
        </>
    )
}
