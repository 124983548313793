import React, { useState } from 'react'
import { Box, IconButton, Typography, Grid, Stack } from "@mui/material"
import { ReactComponent as DetailsIcon } from "../../../Icons/Account/Details.svg"
import { ReactComponent as ApplicationIcon } from "../../../Icons/Account/Application.svg"
import { ReactComponent as ChecklistIcon } from "../../../Icons/Account/Checklist.svg"
import { ReactComponent as LikedIcon } from "../../../Icons/Account/Liked.svg"
import { ReactComponent as PreferenceIcon } from "../../../Icons/Account/Preference.svg"
import Details from './Details'
import Application from './Application'
import Checklist from './CheckList'
import Liked from './Liked'
import Preference from './Preference'


export default function Account() {

    const [renderBlock, setRenderBlock] = useState(1)

    return (
        <Box>
            <Grid container px={{ md: 7, xs: 4 }} py={3}>
                <Grid xs={12} pb={3} borderBottom='.5px solid #737373'>
                    <Typography variant="h6_1">Account</Typography>
                </Grid>
                <Grid lg={2.1} md={3} sm={4} xs={12} height="100vh" borderRight='.5px solid #737373'>
                    <Stack direction="column" gap={3} py={4} px={2}>
                        <Stack onClick={() => { setRenderBlock(1) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 1 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 1 ? "#1A2461" : "#737373" }}>
                            <IconButton><DetailsIcon fill={renderBlock == 1 ? "#1A2461" : "#737373"} /></IconButton>
                            <Typography variant="h9_1">My details</Typography>
                        </Stack>
                        <Stack onClick={() => { setRenderBlock(2) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 2 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 2 ? "#1A2461" : "#737373" }}>
                            <IconButton><ApplicationIcon fill={renderBlock == 2 ? "#1A2461" : "#737373"} /></IconButton>
                            <Typography variant="h9_1">Application</Typography>
                        </Stack>
                        <Stack onClick={() => { setRenderBlock(3) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 3 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 3 ? "#1A2461" : "#737373" }}>
                            <IconButton><ChecklistIcon fill={renderBlock == 3 ? "#1A2461" : "#737373"} /></IconButton>
                            <Typography variant="h9_1">Check list</Typography>
                        </Stack>
                        <Stack onClick={() => { setRenderBlock(4) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 4 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 4 ? "#1A2461" : "#737373" }}>
                            <IconButton><LikedIcon fill={renderBlock == 4 ? "#1A2461" : "#737373"} /></IconButton>
                            <Typography variant="h9_1">Liked</Typography>
                        </Stack>
                        <Stack onClick={() => { setRenderBlock(5) }} direction="row" alignItems="center" gap="10px" sx={{ height: "36px", width: "170px", backgroundColor: renderBlock == 5 ? "#F5F8FA" : "", borderRadius: "8px", color: renderBlock == 5 ? "#1A2461" : "#737373" }}>
                            <IconButton><PreferenceIcon fill={renderBlock == 5 ? "#1A2461" : "#737373"} /></IconButton>
                            <Typography variant="h9_1">Preference</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid py={4} pl={3} lg={9.9} md={9} sm={8} xs={12}>
                    {renderBlock === 1 && <Details />}
                    {renderBlock === 2 && <Application />}
                    {renderBlock === 3 && <Checklist />}
                    {renderBlock === 4 && <Liked />}
                    {renderBlock === 5 && <Preference />}
                </Grid>
            </Grid>
        </Box >
    )
}
