import React, { useState } from 'react'
import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem, Checkbox } from '@mui/material'
import { ReactComponent as CorrectIcon } from "../../../Icons/Account/CorrectIcon.svg"
import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
export default function CheckoutForm() {

    const { control, formState: { errors } } = useForm({
        mode: "onTouched"
    })

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" padding="42px 60px" sx={{ borderBottom: ".5px solid #1A2461" }}>
                <Stack gap="10px">
                    <Typography variant="h4_1">Checkout Form</Typography>
                    <Typography variant="h9_4" color="neutral_4">Fill in the required details</Typography>
                </Stack>
            </Stack>
            <Grid px={{ md: 10, xs: 3 }} mt={3} mb={6} container rowGap={7} columnGap={5}>
                <Grid xs={12} pb={2} borderBottom=".5px solid #737373">
                    <Typography variant='h6_1'>Checkout Notice</Typography>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="center">
                    <Stack gap="15px">
                        <Stack direction="row" gap="5px">
                            <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                            <Typography variant='h9_3' color="#737373">(“the Landlord/Licensor”)</Typography>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>
                        <Typography variant='h7_1' color="#1A2461">DR Pacific Holdings Pte Ltd</Typography>
                        <Typography variant='h9_3' color="#1A2461">Vinga Hall<br />
                            Address: No. 99 Rangoon Road Singapore 218384<br />
                            UEN: 200821037N</Typography>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="center">
                    <Stack gap="15px">
                        <Stack direction="row" gap="5px">
                            <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                            <Typography variant='h9_3' color="#737373">(“the Landlord/Licensor”)</Typography>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>

                        <InputLabel sx={{ mb: 0 }}>Full name</InputLabel>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={(field) => <TextField {...field} />}
                        />
                        <InputLabel sx={{ mb: 0 }}>Room number</InputLabel>
                        <Controller
                            name="room_no"
                            control={control}
                            rules={{ required: true }}
                            render={(field) => <TextField {...field} />}
                        />
                        <InputLabel sx={{ mb: 0 }}>Contact number</InputLabel>
                        <Controller
                            name="contact_no"
                            control={control}
                            rules={{ required: true }}
                            render={(field) => <TextField {...field} />}
                        />
                    </Stack>
                </Grid>
                <Grid xs={12} sm={5} md={4} alignItems="end">
                    <Stack gap="15px">
                        <Stack direction="row" gap="15px" alignItems="end">
                            <Stack direction="row" gap="5px" alignItems="end">
                                <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                                <Typography variant='h9_3' color="#1A2461">requires</Typography>
                            </Stack>
                            <Stack direction="row" gap="5px" alignItems="end">
                                <Typography variant='h7_1' color="#1A2461">PARTY B</Typography>
                                <Typography variant='h9_3' color="#1A2461">to move out of Vinga Hall by</Typography>
                            </Stack>
                        </Stack>
                        <Typography variant='h9_3' color="#1A2461">Dated: 30.05.2025</Typography>
                    </Stack>
                    <Stack mt={2} direction="row" gap="5px" alignItems="end">
                        <Typography variant='h7_1' color="#1A2461">PARTY A</Typography>
                        <Typography variant='h9_3' color="#1A2461">requires</Typography>
                    </Stack>
                    <Grid container columnGap={3}>
                        <Grid xs={5.5}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Date</InputLabel>
                            <DatePicker sx={{ width: "100%", '.MuiSvgIcon-root': { fill: "#1A2461" } }} format="LL" fullWidth />
                        </Grid>
                        <Grid xs={5.5} >
                            <InputLabel sx={{ mt: 3 }} variant="standard">Time</InputLabel>
                            <TimeField sx={{ width: "100%" }} />
                        </Grid>
                    </Grid>
                    <Stack mt={2} gap="10px" direction="row" alignItems="center" justifyContent="start" >
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">I understand and agree this notice</Typography>
                    </Stack>
                    <InputLabel sx={{ mt: 3, color: "#1A2461" }} variant="standard">Signed by</InputLabel>
                    <TextField sx={{ width: "260px", '.MuiInputBase-input': { backgroundColor: "#e6f2fa" } }} variant="filled" />
                    <Stack mt={5} direction="row" alignItems="center" justifyContent="start">
                        <Button variant="standard" >Continue</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box >
    )
}
