import { Typography, Stack, Box, Button, Divider, Grid, Select, InputLabel, IconButton, TextField, MenuItem, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as ChecklistFormIcon } from "../../../../Icons/Account/ChecklistForm.svg"
import { ReactComponent as SandClockIcon } from "../../../../Icons/Account/SandClock.svg"
import { ReactComponent as PuzzlePiecesIcon } from "../../../../Icons/Account/PuzzlePieces.svg"
import { ReactComponent as TickIcon } from "../../../../Icons/Account/TickIcon.svg"
import { ReactComponent as ApprovedIcon } from "../../../../Icons/Account/ApprovedIcon.svg"
import { ReactComponent as RightArrowIcon } from "../../../../Icons/rightArrow.svg"
import { ReactComponent as DownloadIcon } from "../../../../Icons/Account/Download.svg"
import { ReactComponent as ShareIcon } from "../../../../Icons/ShareIcon.svg"
import { ReactComponent as RejectedIcon } from "../../../../Icons/Account/RejectedIcon.svg"



export default function Checklist() {
    return (
        <Box>
            <Stack mb={1} direction="row" alignItems="center" justifyContent="start" gap="100px">
                <Typography variant="h7_1">Furniture & Equipment Checklist</Typography>
            </Stack>
            <Divider />
            <Stack mt={2} direction="column" gap="20px" sx={{ border: ".5px solid #737373", borderRadius: "8px", padding: "20px 30px" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" gap="20px">
                        <ChecklistFormIcon />
                        <Box>
                            <Typography variant="h7_1">Checklist</Typography><br />
                            <Typography variant="h9_5">Furniture & Equipment checklist</Typography>
                            <Stack mt={1} direction="row" alignItems="center" justifyContent="center" gap="8px" sx={{ width: "100px", height: "30px", border: "1px solid #C38236", backgroundColor: "#f9f3eb", borderRadius: "30px", padding: "10px", color: "#C38236" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Pending</Typography>
                                <SandClockIcon style={{ width: "10px", height: "13px" }} />
                            </Stack>

                            {/* <Stack mt={1} direction="row" justifyContent="center" alignItems="center" gap="8px" sx={{ width: "100px", height: "30px", border: "1px solid #52AD33", backgroundColor: "#eef7eb", borderRadius: "30px", padding: "10px", color: "#52AD33" }}>
                                <Typography sx={{ fontWeight: 700 }} variant="h9_5">Done</Typography>
                                <ApprovedIcon />
                            </Stack> */}
                        </Box>
                    </Stack>
                    <Button endIcon={<RightArrowIcon />} variant='contained' sx={{ fontSize: "16px", fontWeight: 700 }}>Complete now</Button>
                </Stack>
            </Stack>
            <Stack mt={3} direction="column">
                <Stack mb={1} direction="row" alignItems="center" justifyContent="start" gap="100px">
                    <Typography variant="h7_1">Terms & Conditions</Typography>
                </Stack>
                <Divider />
                <Typography sx={{ listStyleType: 'disc', display: 'list-item', mt: 2, ml: 4 }} variant="h7_2">No wall pasting of any material is allowed in the room and toilet of the hostel. The cost of repainting of the damaged wall will be borne by the student. </Typography>
                <Typography sx={{ listStyleType: 'disc', display: 'list-item', ml: 4 }} variant="h7_2"> Dining in room is strictly prohibited, avoid using high voltage equipment such as kettle, iron, rice-cooker etc. Violator will be fined $50 each time. For dining, please use the multi-functional hall on first floor. Please keep the room clean &tidy.</Typography>
                <Typography sx={{ listStyleType: 'disc', display: 'list-item', ml: 4 }} variant="h7_2">According to Singapore regulation, smoking is prohibited within the hostel premises. Violator will be fined $300 each time.</Typography>
                <Typography sx={{ listStyleType: 'disc', display: 'list-item', ml: 4 }} variant="h7_2">All furniture & equipment is in good working condition. In case of damage due to improper use, the original price listed in the check-list will be charged to the tenant </Typography>
            </Stack>
            <Stack mt={2} gap="10px" direction="row" alignItems="center" justifyContent="start" >
                <Checkbox />
                <Typography variant='h9_1' color="text_blue">I agree all the provided Information are correct</Typography>
            </Stack>
            <Stack my={3} direction="row" alignItems="center" justifyContent="start">
                <Button variant="standard" >Complete</Button>
            </Stack>
        </Box >
    )
}
