import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Button } from '@mui/material'
import PaynowIcon from "../../../../Icons/PaynowIcon.svg"
import VisaIcon from "../../../../Icons/Visa.svg"

export default function Payment({ setStepperCount }) {
    return (
        <>
            <Box px={{ md: 10, xs: 3 }}>
                <Typography variant="h6_1">Registration payment</Typography>
                <Grid mt={3} mb={6} container rowGap={7}>
                    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                        <Divider />
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Deposit</Typography>
                                <Typography variant='h9_4' >Deposit fees to be done for the selected room(  Excluding rent )</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider />
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Registration fees</Typography>
                                <Typography variant='h9_4' >Registration fees to be done (Non-refundable)</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider />
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Supporting fees</Typography>
                                <Typography variant='h9_4' >Supporting fees to be done (Non-refundable)</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider />
                        <Stack my={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Other Services</Typography>
                                <Typography variant='h9_4' >Landing Service Package - S$400</Typography>
                                <Typography variant='h9_4' >Counselling - S$100</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 20</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack gap="8px">
                                <Typography variant="h6_1">Total</Typography>
                            </Stack>
                            <Typography variant="h6_1">S$ 560</Typography>
                        </Stack>
                        <Stack mt={10} direction="column" justifyContent="space-between" alignItems="center" gap="10px">
                            <Typography variant="h6_1">Pay Via</Typography>
                            <Stack gap="8px" direction="row" justifyContent="space-between" alignItems="center">
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={PaynowIcon}></Box>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" height="44px" width="200px" border=".5px solid #1A2461" sx={{ borderRadius: "8px" }}>
                                    <Box margin="0 auto" component="img" src={VisaIcon}></Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>


                <Stack my={5} direction="row" alignItems="center" justifyContent="start">
                    <Button variant="standard" onClick={() => { setStepperCount(4) }}>Back</Button>
                </Stack>
            </Box>

        </>
    )
}
