import './App.css';
import Dashboard from './Pages/User/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Main from './Pages/User/Main';
import NewsReview from './Pages/User/News/NewsReview';
import NewsList from './Pages/User/News/NewsList';
import Application from './Pages/User/Application';
import Services from './Pages/User/Services';
import Intro from './Pages/User/Intro';
import Account from './Pages/User/Account';
import ContactUs from './Pages/User/Account/ContactUs';
import ChecklistItems from './Pages/User/Account/CheckList/ChecklistItems';
import CheckoutForm from './Pages/User/Account/CheckoutForm';
import BreakContact from './Pages/User/Account/BreakContact.jsx';
import HostelReview from './Pages/User/HostelReview';
import LandingPage from './LandingPage';

import AdminDashboard from './Pages/Admin/Dashboard';
import AnnouncementList from './Pages/Admin/Announcement/AnnouncementList';
import AnnouncementAdd from './Pages/Admin/Announcement/AnnouncementAdd';
import AdminNewsList from './Pages/Admin/News/NewsList';
import AdminNewsAdd from './Pages/Admin/News/NewsAdd';
import AdminHostelAdd from './Pages/Admin/Hostel/HostelAdd';
import Hostellist from "./Pages/Admin/Hostel/Hostellist";
import AdminApplication from "./Pages/Admin/Application/ApplicationList";
import AdminServices from "./Pages/Admin/Services"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LandingPage />} />

          {/* User/ */}

          <Route path="/" element={<Dashboard />} >

            <Route path="/account" element={<Account />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/Checklist" element={<ChecklistItems />} />
            <Route path="/CheckoutForm" element={<CheckoutForm />} />
            <Route path="/Breakcontact" element={<BreakContact />} />



            <Route index element={<Main />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/news/:id" element={<NewsReview />} />
            <Route path="application" element={<Application />} />
            <Route path="/services" element={<Services />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/hostelreview/:id" element={<HostelReview />} />
            <Route path="/*" element={<h1>Page Not Found</h1>} />
          </Route>

          <Route path="/Admin" element={<AdminDashboard />}>
            {/* <Route path="/Admin/AnnouncementList" element={<AnnouncementList />} /> */}
            <Route index element={<AnnouncementList />} />
            <Route path="/Admin/AnnouncementAdd" element={<AnnouncementAdd />} />
            <Route path="/Admin/NewsList" element={<AdminNewsList />} />
            <Route path="/Admin/NewsAdd" element={<AdminNewsAdd />} />
            <Route path="/Admin/HostelAdd" element={<AdminHostelAdd />} />
            <Route path="/Admin/Hostellist" element={<Hostellist />} />
            <Route path="/Admin/Application" element={<AdminApplication />} />
            <Route path="/Admin/Services" element={<AdminServices />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
